import batti_soju_1 from "./images/product/batti_soju/batti_soju_1.png";
import batti_soju_2 from "./images/product/batti_soju/batti_soju_2.png";
import batti_soju_3 from "./images/product/batti_soju/batti_soju_3.png";
import batti_soju_4 from "./images/product/batti_soju/batti_soju_4.png";
import batti_soju_5 from "./images/product/batti_soju/batti_soju_5.png";
import batti_soju_6 from "./images/product/batti_soju/batti_soju_6.png";
import damsoul_1 from "./images/product/damsoul/damsoul_1.png";
import damsoul_2 from "./images/product/damsoul/damsoul_2.png";
import damsoul_3 from "./images/product/damsoul/damsoul_3.png";
//import gyeoul_1 from "./images/product/gyeoul/gyeoul_1.png";
//import gyeoul_2 from "./images/product/gyeoul/gyeoul_2.png";
import leegangju_19_1 from "./images/product/leegangju_19/leegangju_19_1.png";
import leegangju_19_2 from "./images/product/leegangju_19/leegangju_19_2.png";
import leegangju_25_1 from "./images/product/leegangju_25/leegangju_25_1.png";
import leegangju_25_2 from "./images/product/leegangju_25/leegangju_25_2.png";
import nimome_1 from "./images/product/nimome/nimome_1.png";
import nimome_2 from "./images/product/nimome/nimome_2.png";
import nimome_3 from "./images/product/nimome/nimome_3.png";
import soju_yeoyoo_1 from "./images/product/soju_yeoyoo/soju_yeoyoo_1.png";
import soju_yeoyoo_2 from "./images/product/soju_yeoyoo/soju_yeoyoo_2.png";
import soju_yeoyoo_3 from "./images/product/soju_yeoyoo/soju_yeoyoo_3.png";
import soju_yeoyoo_4 from "./images/product/soju_yeoyoo/soju_yeoyoo_4.png";
import solsongju_1 from "./images/product/solsongju/solsongju_1.png";
import solsongju_2 from "./images/product/solsongju/solsongju_2.png";
import songiju_1 from "./images/product/songiju/songiju_1.png";
import songiju_2 from "./images/product/songiju/songiju_2.png";
import songiju_3 from "./images/product/songiju/songiju_3.png";
import songiju_4 from "./images/product/songiju/songiju_4.png";
import songiju_5 from "./images/product/songiju/songiju_5.png";
import wangju_1 from "./images/product/wangju/wangju_1.png";
import wangju_2 from "./images/product/wangju/wangju_2.png";
import yakju_set_1 from "./images/product/yakju_set/yakju_set_1.png";
import yakju_set_2 from "./images/product/yakju_set/yakju_set_2.png";
import yangchon_chungju_1 from "./images/product/yangchon_chungju/yangchon_chungju_1.png";
import yangchon_chungju_2 from "./images/product/yangchon_chungju/yangchon_chungju_2.png";
import yangchon_chungju_3 from "./images/product/yangchon_chungju/yangchon_chungju_3.png";
import yangchon_chungju_4 from "./images/product/yangchon_chungju/yangchon_chungju_4.png";
import yon_1 from "./images/product/yon/yon_1.png";
import yon_2 from "./images/product/yon/yon_2.png";
import yon_3 from "./images/product/yon/yon_3.png";
import yunguna_gift_1 from "./images/product/yunguna_gift/yunguna_gift_1.png";
import yunguna_gift_2 from "./images/product/yunguna_gift/yunguna_gift_2.png";
import yunguna_makgeolli_1 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_1.png";
import yunguna_makgeolli_2 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_2.png";
import yunguna_makgeolli_3 from "./images/product/yunguna_makgeolli/yunguna_makgeolli_3.png";
import yunguna_takju_1 from "./images/product/yunguna_takju/yunguna_takju_1.png";
import yunguna_takju_2 from "./images/product/yunguna_takju/yunguna_takju_2.png";
import yunguna_takju_3 from "./images/product/yunguna_takju/yunguna_takju_3.png";
import yunguna_yakju_1 from "./images/product/yunguna_yakju/yunguna_yakju_1.png";
import yunguna_yakju_2 from "./images/product/yunguna_yakju/yunguna_yakju_2.png";
import yunguna_yakju_3 from "./images/product/yunguna_yakju/yunguna_yakju_3.png";

export enum BrowseBy {
  all = "all",
  yakju = "yakju",
  takju = "takju",
  soju = "soju",
  set = "set",
}

export type ProductInfo = {
  producer?: string;
  name?: string;
  region?: string;
  productVolumeML?: number;
  alcoholPercent?: number;
  category?: "Fermented" | "Spirits" | "Liqueurs";
  type?: BrowseBy;
  ingredients?: string[];
};

export type ProductDetailProps = {
  available: boolean;
  id: number | string;
  name: string;
  nameKor: string;
  businessPrice: number;
  price: number;
  prevPrice?: number;
  desc?: string;
  description?: {
    desc: string;
    intro: string;
  };
  intro?: string;
  src?: string;
  images: string[];
  inStock: {
    berlin?: number;
    hamburg?: number;
    hanover?: number;
    total?: number;
  };
  taste?: {
    sweet: number;
    rich: number;
    flavor: number;
  };
  sort?: {
    admin?: number;
  };
  productInfo?: ProductInfo;
  setProductInfo?: ProductInfo[];
  howTo?: {
    enjoy?: string;
    pair?: string;
  };
};

export const mapImages: any[] = [
  {
    id: 1,
    images: [nimome_1, nimome_2, nimome_3],
  },
  {
    id: 2,
    images: [wangju_1, wangju_2],
  },
  {
    id: 3,
    images: [solsongju_1, solsongju_2],
  },
  {
    id: 4,
    images: [
      yangchon_chungju_1,
      yangchon_chungju_2,
      yangchon_chungju_3,
      yangchon_chungju_4,
    ],
  },
  {
    id: 5,
    images: [yunguna_yakju_1, yunguna_yakju_2, yunguna_yakju_3],
  },
  {
    id: 6,
    images: [yunguna_takju_1, yunguna_takju_2, yunguna_takju_3],
  },
  {
    id: 7,
    images: [yunguna_makgeolli_1, yunguna_makgeolli_2, yunguna_makgeolli_3],
  },
  {
    id: 9,
    images: [leegangju_19_1, leegangju_19_2],
  },
  {
    id: 10,
    images: [leegangju_25_1, leegangju_25_2],
  },
  {
    id: 11,
    images: [soju_yeoyoo_1, soju_yeoyoo_2, soju_yeoyoo_3, soju_yeoyoo_4],
  },
  {
    id: 12,
    images: [
      batti_soju_1,
      batti_soju_2,
      batti_soju_3,
      batti_soju_4,
      batti_soju_5,
      batti_soju_6,
    ],
  },
  {
    id: 13,
    images: [damsoul_1, damsoul_2, damsoul_3],
  },
  {
    id: 14,
    images: [yunguna_gift_1, yunguna_gift_2],
  },
  {
    id: 15,
    images: [yakju_set_1, yakju_set_2],
  },
  {
    id: 16,
    images: [yon_1, yon_2, yon_3],
  },
  {
    id: 17,
    images: [songiju_1, songiju_2, songiju_3, songiju_4, songiju_5],
  },
];
