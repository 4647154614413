import { useEffect, useState } from "react";

import { Customer } from "types/customer";
import LoadingOverlay from "react-loading-overlay-ts";
import { getAllCustomers } from "firestore/customers";
import { getFullAddress } from "utils/helper";

export const PartnersView = () => {
  const [customers, setCustomers] = useState<Customer[] | null>(null);
  useEffect(() => {
    getAllCustomers().then((res: Customer[]) => {
      const aYearAgo = new Date();
      aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
      setCustomers(
        res
          .filter(
            ({ lastOrdered, hidePartner }) =>
              lastOrdered?.seconds &&
              new Date(lastOrdered?.seconds * 1000) > aYearAgo &&
              !hidePartner
          )
          .sort((a, b) =>
            (a.shortName ?? a.name) < (b.shortName ?? b.name) ? -1 : 1
          )
      );
      console.log(customers);
    });
  }, []);
  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <div className="w-11/12 h-full mx-auto mt-24 md:w-3/4 lg:w-2/3">
        <h3 className="w-full my-8 text-2xl font-semibold">Partners</h3>
        <div className="flex">
          <span className="w-full font-semibold text-left text-md">
            Many of our products are also available offline through our
            partners.
          </span>
        </div>
        <div className="mt-8">
          <h3 className="text-xl text-left">Stores</h3>
          <ul className="mt-4 mb-8 space-y-4">
            <li className="flex flex-col text-left list-none w-[48rem]">
              <h4 className="font-bold">
                <a href="https://www.vinhloi.de/" target="_blank">
                  Vinh-Loi
                </a>
              </h4>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.com/maps/place/Vinh-Loi+Asien+Supermarkt/@52.4637451,13.329763,198a,35y,297.68h,53.35t/data=!3m1!1e3!4m5!3m4!1s0x0:0x20c5cc8b0ba9309a!8m2!3d52.4645304!4d13.3256365?hl=de-DE"
                  target="_blank"
                >
                  Gutsmuthsstr.23 24 | 12163 Berlin
                </a>
                <a href="tel:+49308593678">+49 30 8593678</a>
              </div>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.de/maps/place/Ansbacher+Str.+16,+10787+Berlin/@52.5017545,13.3441748,455a,35y,285.34h,16.55t/data=!3m1!1e3!4m5!3m4!1s0x47a8505468f13aa3:0x2d774221aa4ae10f!8m2!3d52.5032851!4d13.342306"
                  target="_blank"
                >
                  Ansbacher Str.16 | 10787 Berlin
                </a>
                <a href="tel:+49302350900">+49 30 2350900</a>
              </div>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.com/maps/place/VINH-LOI+Asien+Supermarkt/@52.5489398,13.351557,770m/data=!3m2!1e3!4b1!4m5!3m4!1s0x47a853d5ea53ad89:0x57f95f7c8453313b!8m2!3d52.5489366!4d13.3537457?hl=de"
                  target="_blank"
                >
                  Müllerstraße 141 | 13353 Berlin
                </a>
                <a href="tel:+493040995268">+49 30 40995268</a>
              </div>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.com/maps/place/Vinh-Loi+Asien+Supermarkt/@53.5732984,10.0624551,304m/data=!3m1!1e3!4m6!3m5!1s0x47b18eab6bb1c851:0x80185d25b72db596!8m2!3d53.5727564!4d10.0624174!16s%2Fg%2F11b6gq7bqy?hl=de-DE&entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                >
                  Wandsbeker Königstr.23 | 22041 Hamburg
                </a>
                <a href="tel:+4940806071710">+49 40 806071710</a>
              </div>
            </li>
            <li className="flex flex-col text-left list-none w-[48rem]">
              <h4 className="font-bold">
                <a href="http://thegangnam.de/" target="_blank">
                  The Gangnam
                </a>
              </h4>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.com/maps/place/The+Gangnam/data=!4m2!3m1!1s0x0:0x1cbbced04340380f?sa=X&ved=1t:2428&ictx=111&cshid=1740344067539112"
                  target="_blank"
                >
                  Stresemannstr. 33 | 40210 Düsseldorf
                </a>
                <a href="tel:+4921117805591">+49 211 17805591</a>
              </div>
              <div className="flex justify-between w-[36rem] my-2">
                <a
                  className="flex flex-col w-fit"
                  href="https://www.google.com/maps/place/Friedrichstra%C3%9Fe+133,+40217+D%C3%BCsseldorf,+Germany/@51.2083464,6.774539,1447m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47b8ca421f5bdef3:0x6fb255cff629a51f!8m2!3d51.2083464!4d6.774539!16s%2Fg%2F11bw3wrgyr?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                >
                  Friedrichstr. 133 | 40217 Düsseldorf
                </a>
                <a href="tel:+4921117805591">+49 211 17805591</a>
              </div>
            </li>
          </ul>
          {/*<h3 className="text-xl text-left">Restaurants</h3>
          <ul className="mt-4 mb-8 space-y-4">
            {customers &&
              customers.map((e) => (
                <li
                  className="flex flex-col text-left list-none w-[48rem]"
                  key={e.accountNumber}
                >
                  {e?.homepage ? (
                    <a href={e.homepage}>
                      <h4 className="font-bold">{e?.shortName ?? e?.name}</h4>
                    </a>
                  ) : (
                    <h4 className="font-bold">{e?.shortName ?? e?.name}</h4>
                  )}
                  <div className="flex justify-between w-[36rem] my-2">
                    <span className="flex flex-col w-fit">
                      {getFullAddress(e.address)}
                    </span>
                  </div>
                </li>
              ))}
          </ul>*/}
        </div>
      </div>
    </div>
  );
};

export default PartnersView;
