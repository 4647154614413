import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";

import { ProductDetailProps } from "assets/Content";
import { app } from "./firestore";
import { mapImages } from "assets/Content";

export const getAllProducts = async (
  getNAProduct?: boolean
): Promise<ProductDetailProps[]> => {
  const db = getFirestore(app);
  const productRef = collection(db, "products");
  const querySnapshot = await getDocs(productRef);
  const allProducts: any[] = [];

  querySnapshot.forEach(async (doc) => {
    if (doc.data()?.available || getNAProduct) {
      const images = mapImages.find(({ id }) => id == doc.id)?.images ?? [];
      allProducts.push({
        ...doc.data(),
        id: doc.id,
        desc: doc.data()?.description?.desc,
        images,
      });
    }
  });
  return allProducts;
};

export const editProduct = async (
  data: any,
  id: string
): Promise<ProductDetailProps[]> => {
  const db = getFirestore(app);
  const productRef = doc(db, `products/${id}`);
  await updateDoc(productRef, data);
  return await getAllProducts(true);
};
