import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import {  Tab, Tabs } from "@mui/material";
import { Order } from "types/order";
import ReactDatePicker from "react-datepicker";
import { queryOrderByTime } from "firestore/orders";
import { useEffect, useState } from "react";
import ByCustomers from "./dashboard/byCustomers";
import ByTrend from "./dashboard/byTrend";
import ByProducts from "./dashboard/byProducts";

export const Dashboard = ({
  allOrders,
  allCustomers,
  allProducts,
  setAllOrders,
}: {
  allOrders: any[];
  allCustomers: any[];
  allProducts: any[];
  setAllOrders: any;
}) => {
  const now = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 60);

  const [fromDate, setFromDate] = useState<Date>(startDate);
  const [toDate, setToDate] = useState<Date>(now);
  const [currentTab, setCurrentTab] = useState<number>(0);

  useEffect(() => {
    queryOrderByTime(fromDate, toDate).then((res) => {
      setAllOrders([...res]);
    });
  }, [fromDate, toDate]);

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Dashboard</h3>
        <button
          className="my-2"
          onClick={() => {
            queryOrderByTime(fromDate, toDate).then((res) => {
              setAllOrders([...res]);
            });
          }}
        >
          Refresh
        </button>
        {allOrders.length > 0 && (
          <div className="w-11/12 mx-auto">
              <div className="flex float-right mb-4 text-lg w-fit">
                <span className="px-2">From:</span>
              <ReactDatePicker
                  selected={fromDate}
                  onChange={(date: Date) => setFromDate(date)}
                />
                <span className="px-2">To:</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={(date: Date) => setToDate(date)}
                />
              </div>
              <Tabs
                value={currentTab}
                onChange={(e, tabIndex) => setCurrentTab(tabIndex)}
              >
                <Tab label="By Customers" value={0} />
                <Tab label="By Products" value={1} />
                <Tab label="Trend" value={2} />
              </Tabs>
            {currentTab === 0 && (
              <ByCustomers allCustomers={allCustomers} allOrders={allOrders}/>
              )}
              {currentTab === 1 && (
<ByProducts allOrders={allOrders} allProducts={allProducts} />
            )}
                          {currentTab === 2 && (
                <ByTrend allCustomers={allCustomers} allOrders={allOrders} fromDate={fromDate} toDate={toDate} />
              )}
            </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
