import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { ChangeEvent, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MenuItem, Modal, Radio, Select, TextField } from "@mui/material";
import { editProduct, getAllProducts } from "firestore/products";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import EditIcon from "@mui/icons-material/Edit";
import NumberField from "components/Inputs/NumberField";
import StringField from "components/Inputs/StringField";
import { createPurchase } from "firestore/purchases";
import { createTransfer } from "firestore/transfers";
import { isNumber } from "@mui/x-data-grid/internals";

export const Inventories = ({
  allProducts,
  allLocations,
  setAllProducts,
}: {
  allProducts: any[];
  allLocations: any[];
  setAllProducts: any;
}) => {
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isPurhcaseType, setIsPurchaseType] = useState<boolean>(false);
  const [created, setCreated] = useState<any>();
  const [fromLoc, setFromLoc] = useState<string>();
  const [toLoc, setToLoc] = useState<string>();
  const [note, setNote] = useState<string>("");
  const [editMode, setEditMode] = useState<number>(-1);
  const [updateProduct, setUpdateProduct] = useState<any>(null);

  const editPriceButton = (params: any) => (
    <button
      onClick={() => {
        const product = allProducts.find(({ id }) => id == params.row.id);
        product.businessPrice = product.businessPrice.toFixed(2);
        product.price = product.price.toFixed(2);
        product.prevPrice = product.prevPrice.toFixed(2);
        setEditMode(params.row.id);
        setUpdateProduct({
          ...product,
        });
      }}
    >
      <EditIcon />
    </button>
  );

  const setAvailabilitySelect = (params: any) => (
    <Select
      className="w-[7rem] h-[2rem]"
      label="Availability"
      defaultValue={
        allProducts.find(({ id }) => id == params.row.id).available
          ? "Available"
          : "NA"
      }
      value={
        allProducts.find(({ id }) => id == params.row.id).available
          ? "Available"
          : "NA"
      }
      onChange={(e: any) => {
        allProducts[
          allProducts.findIndex(({ id }) => id == params.row.id)
        ].available = e.target.value == "Available";
        setAllProducts([...allProducts]);
        editProduct(
          { available: e.target.value == "Available" },
          params.row.id,
        );
      }}
    >
      <MenuItem value={"Available"}>Available</MenuItem>
      <MenuItem value={"NA"}>NA</MenuItem>
    </Select>
  );

  const columns: GridColDef[] = [
    { field: "productName", headerName: "Product", minWidth: 300 },
    { field: "inStock", headerName: "Total in Stock", minWidth: 120 },
    { field: "inStockHamburg", headerName: "Stock (Hamburg)", minWidth: 120 },
    { field: "inStockBerlin", headerName: "Stock (Berlin)", minWidth: 120 },
    { field: "inStockHanover", headerName: "Stock (Hanover)", minWidth: 120 },
    {
      field: "businessPrice",
      headerName: "B2B",
      minWidth: 120,
    },
    {
      field: "consumerPrice",
      headerName: "B2C",
      minWidth: 120,
    },
    {
      field: "consumerPricePrev",
      headerName: "B2C (Previous)",
      minWidth: 120,
    },
    {
      field: "editPrice",
      headerName: "",
      renderCell: editPriceButton,
      minWidth: 50,
      width: 50,
    },
    {
      field: "available",
      headerName: "Availability",
      renderCell: setAvailabilitySelect,
      minWidth: 140,
    },
  ];


  const rows = allProducts.map((product) => ({
    id: product.id,
    productName: `${product.name} (${product.nameKor})`,
    inStock: product?.inStock?.total ?? "NA",
    inStockHamburg: product?.inStock?.hamburg ?? "NA",
    inStockBerlin: product?.inStock?.berlin ?? "NA",
    inStockHanover: product?.inStock?.hanover ?? "NA",
    businessPrice:
      product?.businessPrice && isNumber(product?.businessPrice)
        ? `\u20AC${product.businessPrice.toFixed(2)}`
        : "NA",
    consumerPrice:
      product?.price && isNumber(product?.price)
        ? `\u20AC${product.price.toFixed(2)}`
        : "NA",
    consumerPricePrev:
      product?.prevPrice && isNumber(product?.prevPrice)
        ? `\u20AC${product.prevPrice.toFixed(2)}`
        : "NA",
    available: product?.available ? "Available" : "NA",
  }));

  const handleClose = () => {
    getAllProducts(true).then((res) => {
      setAllProducts(res);
      setEditMode(-1);
      setUpdateProduct(null);
    });
  };

  const handleUpdate = (data: any, id: string) => {
    data.businessPrice = Number(Number(data.businessPrice).toFixed(2));
    data.price = Number(Number(data.price).toFixed(2));
    data.prevPrice = Number(Number(data.prevPrice).toFixed(2));
    data.description.desc = data.desc;
    editProduct(data, id).then((res) => {
      setAllProducts(res);
      handleClose();
    });
  };

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <Modal
        open={editMode > 0}
        onClose={handleClose}
        className="w-5/6 max-w-6xl m-auto bg-white rounded-lg h-fit sm:w-3/4 md:w-3/4 lg:w-2/3 xl:w-1/2"
      >
        <div className="flex-row w-full h-full mx-auto overflow-scroll text-center bg-white rounded-lg">
          <h3 className="inline-block w-full mt-4 text-2xl text-center md:mt-8 md:text-4xl font-akrobat">
            Update Product
          </h3>
          <h4 className="inline-block w-full mb-4 text-xl text-center md:text-2xl font-akrobat">
            {allProducts.find(({ id }) => editMode == id)?.name}
          </h4>
          <div className="justify-center inline-block w-5/6 mx-auto space-y-4 text-center text-md md:text-lg h-fit">
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full my-auto">B2B Price:</span>
              <button
                className="float-right"
                onClick={() =>
                  setUpdateProduct({ ...updateProduct, businessPrice: "" })
                }
              >
                <NumberField
                  value={updateProduct?.businessPrice ?? "-"}
                  handleOnChange={(e: any) =>
                    setUpdateProduct({
                      ...updateProduct,
                      businessPrice: e.target.value,
                    })
                  }
                  showBorder
                />
              </button>
            </div>
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full m-auto">B2C Price:</span>
              <button
                className="float-right"
                onClick={() =>
                  setUpdateProduct({ ...updateProduct, price: "" })
                }
              >
                <NumberField
                  value={updateProduct?.price ?? "-"}
                  handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setUpdateProduct({
                      ...updateProduct,
                      price: e.target.value,
                    })
                  }
                  showBorder
                />
              </button>
            </div>
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full m-auto">
                B2C Price (Previous):
              </span>
              <button
                className="float-right"
                onClick={() =>
                  setUpdateProduct({ ...updateProduct, prevPrice: "" })
                }
              >
                <NumberField
                  value={updateProduct?.prevPrice ?? "-"}
                  handleOnChange={(e: any) =>
                    setUpdateProduct({
                      ...updateProduct,
                      prevPrice: e.target.value,
                    })
                  }
                  showBorder
                />
              </button>
            </div>
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full m-auto">Description:</span>
              <TextField
                type="string"
                size="medium"
                multiline
                rows={3}
                className="w-full"
                value={updateProduct?.desc ?? "-"}
                onChange={(e) =>
                  setUpdateProduct({
                    ...updateProduct,
                    desc: e.target.value,
                  })
                }
              />
            </div>
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full m-auto">How To Enjoy:</span>
              <TextField
                type="string"
                size="medium"
                multiline
                rows={3}
                className="w-full"
                value={updateProduct?.howTo.enjoy ?? "-"}
                onChange={(e) => {
                  updateProduct.howTo.enjoy = e.target.value;
                  setUpdateProduct({
                    ...updateProduct,
                  });
                }}
              />
            </div>
            <div className="inline-block w-3/4 h-full my-auto">
              <span className="float-left h-full m-auto">How To Pair:</span>
              <TextField
                type="string"
                size="medium"
                multiline
                rows={3}
                className="w-full"
                value={updateProduct?.howTo.pair ?? "-"}
                onChange={(e) => {
                  updateProduct.howTo.pair = e.target.value;
                  setUpdateProduct({
                    ...updateProduct,
                  });
                }}
              />
            </div>
          </div>
          <div className="justify-center space-x-4 sm:flex h-fit">
            <div className="my-2 sm:my-8">
              <button
                onClick={() => handleUpdate(updateProduct, editMode.toString())}
                className="w-2/3 px-6 py-2 m-auto text-sm text-white bg-black rounded-lg sm:w-full md:text-md"
              >
                Update
              </button>
            </div>
            <div className="mb-4 sm:my-auto">
              <button
                onClick={() => handleClose()}
                className="w-2/3 px-6 py-2 m-auto text-sm text-white bg-black rounded-lg sm:w-full md:text-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Products</h3>
        <div className="flex w-full h-full mx-12">
          <Accordion className="w-11/12 h-full">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <div className="text-seconadry">Create Purchase / Transfer</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full h-full pt-4 border-t-2">
                <div className="flex-row w-full h-full">
                  <div className="flex-row w-full mx-2 h-fit">
                    <div className="inline-flex w-full">
                      <div className="flex w-1/2">
                        <span className="my-auto mr-2 w-[4rem] text-left">
                          Type
                        </span>
                        <div className="flex">
                          <Radio
                            checked={!isPurhcaseType}
                            onChange={() => setIsPurchaseType(!isPurhcaseType)}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                          <span className="my-auto mr-6">Transfer</span>
                          <Radio
                            checked={isPurhcaseType}
                            onChange={() => setIsPurchaseType(!isPurhcaseType)}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                          <span className="my-auto">Purchase</span>
                        </div>
                      </div>
                      <div className="flex w-1/2">
                        <span className="my-auto mr-2 w-[4rem] text-left">
                          Date
                        </span>
                        <DatePicker
                          selected={created}
                          onChange={(date: Date) => {
                            setCreated(date);
                          }}
                          className="flex px-2 mt-2 border-2 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="flex w-full my-1">
                      <div className="flex-row w-full">
                        <span className="flex w-full my-auto text-left">
                          Product
                        </span>
                        {Array.from(
                          Array(selectedProducts.length + 1).keys(),
                        ).map((index) => (
                          <div className="inline-flex w-full">
                            <span className="my-auto mr-2 w-[4rem] text-left text-md">
                              Item {index}
                            </span>
                            <Select
                              label="Product"
                              className="w-[20rem] h-[2.5rem] my-auto"
                              key={index}
                              //defaultValue={""}
                              onChange={(e) => {
                                if (selectedProducts.includes(e.target.value)) {
                                  alert("Duplicate Product");
                                } else {
                                  if (index == selectedProducts.length)
                                    selectedProducts.push(e.target.value);
                                  else selectedProducts[index] = e.target.value;
                                  const businessPrice =
                                    allProducts.find(
                                      ({ name }) =>
                                        name == (e.target.value as any)?.name,
                                    )?.businessPrice ?? "";
                                  selectedProducts[index].qty = 1;
                                  selectedProducts[index].businessPrice =
                                    businessPrice;
                                  setSelectedProducts([...selectedProducts]);
                                }
                              }}
                            >
                              {allProducts.map((product) => (
                                <MenuItem value={product}>
                                  {product?.name} ({product?.nameKor})
                                </MenuItem>
                              ))}
                            </Select>
                            {selectedProducts[index] && (
                              <div className="flex w-fit">
                                <div className="inline-flex w-[10rem] m-auto ml-2 border h-[2rem]">
                                  <button
                                    className="w-4 m-auto text-lg text-black rounded-md"
                                    onClick={() => {
                                      if (selectedProducts[index].qty) {
                                        selectedProducts[index].qty -= 1;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <button
                                    onClick={() => {
                                      selectedProducts[index].qty = "";
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                  >
                                    <NumberField
                                      className="w-full text-center max-w-[8vh] max-h-fit"
                                      value={selectedProducts[index]?.qty ?? ""}
                                      handleOnChange={(e: any) => {
                                        selectedProducts[index].qty = Number(
                                          e.target.value,
                                        );
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }}
                                      alignText
                                    />
                                  </button>
                                  <button
                                    className="w-6 m-auto text-xl text-black rounded-md"
                                    onClick={() => {
                                      selectedProducts[index].qty += 1;
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                                {isPurhcaseType && (
                                  <div>
                                    <div className="min-w-[6rem] inline-flex w-42 m-auto ml-2 border h-[2rem]">
                                      <button
                                        onClick={() => {
                                          selectedProducts[
                                            index
                                          ].businessPrice = "";
                                          setSelectedProducts([
                                            ...selectedProducts,
                                          ]);
                                        }}
                                      >
                                        <span className="mx-1 my-auto">
                                          {"\u20AC"}
                                        </span>
                                        <NumberField
                                          className="w-48 text-center max-w-[10vh] max-h-fit"
                                          value={
                                            selectedProducts[index]
                                              ?.businessPrice ?? ""
                                          }
                                          handleOnChange={(e: any) => {
                                            selectedProducts[
                                              index
                                            ].businessPrice = Number(
                                              Number(e.target.value).toFixed(2),
                                            );
                                            setSelectedProducts([
                                              ...selectedProducts,
                                            ]);
                                          }}
                                          alignText
                                        />
                                      </button>
                                    </div>

                                    <span className="my-auto ml-4 text-lg">
                                      {"\u20AC"}
                                      {Number(
                                        selectedProducts[index].qty *
                                          selectedProducts[index].businessPrice,
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                        {isPurhcaseType && (
                          <div className="flex justify-end">
                            <div className="absolute inline-flex mr-12">
                              <span className="w-24 mr-4">Total Amount</span>
                              <span className="w-12">
                                {"\u20AC" +
                                  selectedProducts
                                    .reduce(
                                      (prev, curr) =>
                                        curr?.businessPrice * curr?.qty + prev,
                                      //Number(shippingCost) - Number(discount)
                                      0,
                                    )
                                    //* 1.19
                                    .toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="inline-flex w-full my-1 mt-4">
                      <span className="w-[4rem] mr-2 my-auto text-left">
                        From
                      </span>
                      <Select
                        className="w-1/2 h-[2rem]"
                        label="From"
                        onChange={(e: any) => setFromLoc(e.target.value)}
                        placeholder="From"
                      >
                        {allLocations
                          .filter(({ isVendor }) =>
                            isPurhcaseType ? isVendor : !isVendor,
                          )
                          .map((location) => (
                            <MenuItem value={location}>
                              {location?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <div className="inline-flex w-full my-1">
                      <span className="w-[4rem] mr-2 my-auto text-left">
                        To
                      </span>
                      <Select
                        className="w-1/2 h-[2rem]"
                        label="To"
                        onChange={(e: any) => setToLoc(e.target.value)}
                        placeholder="To"
                      >
                        {allLocations
                          .filter(({ isVendor }) => !isVendor)
                          .map((location) => (
                            <MenuItem value={location}>
                              {location?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <StringField
                      value={note}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNote(e.target.value)
                      }
                      label="Note (optional)"
                      labelClass="w-[4rem] mr-2 my-auto text-left"
                    />
                  </div>
                </div>
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={() => {
                    const newEntry = {
                      created,
                      fromLoc,
                      toLoc,
                      note,
                      items: selectedProducts,
                    };
                    (isPurhcaseType
                      ? createPurchase(newEntry)
                      : createTransfer(newEntry)
                    ).then(() => {
                      getAllProducts(true).then((res) => {
                        alert("Inventory Updated");
                        setFromLoc("");
                        setCreated(null);
                        setSelectedProducts([]);
                        setIsPurchaseType(false);
                        setToLoc("");
                        setNote("");
                        setAllProducts([...res]);
                      });
                    });
                  }}
                >
                  {isPurhcaseType ? "Purchase" : "Transfer"}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <button
          className="my-2"
          onClick={() => {
            getAllProducts(true).then((res) => {
              setAllProducts([...res]);
            });
          }}
        >
          Refresh
        </button>
        <div className="w-full h-full mt-4 xl:w-11/12 xl:mx-12">
          <DataGrid
            rows={rows}
            columns={columns}
            rowSelection={false}
            pageSizeOptions={[5, 10, 25, 50]}
            sortingOrder={["desc"]}
            sortModel={[{ field: "id", sort: "desc" }]}
          />
        </div>
      </div>
    </div>
  );
};

export default Inventories;
