import Carousel from "react-multi-carousel";
import NotificationContent from "components/NotificationContent";
import { ProductDetailProps } from "assets/Content";
import { Store } from "react-notifications-component";
import { store } from "store";
import { useFirestore } from "./FirestoreContext";
import { useNavigate } from "react-router-dom";

export const ProductSlider = () => {
  const { products, loading } = useFirestore();
  const navigate = useNavigate();
  const iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  const addProduct = (productId: string) => {
    store.dispatch({
      type: "cart/addNewProduct",
      payload: { id: productId, qty: 1 },
    });
    const productName = products.filter(({ id }: any) => id == productId)[0]
      .name;
    Store.addNotification({
      content: NotificationContent(productName, 1),
      //message: "Product Added",
      type: "default",
      //insert: "top",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      },
    });
  };

  return (
    <div className="h-full rounded-lg">
      <Carousel
        className="z-30"
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px mb-16"
        infinite
        responsive={{
          xxl: {
            breakpoint: { max: 99999, min: 4000 },
            items: 10,
          },
          xl: {
            breakpoint: { max: 4000, min: 1200 },
            items: 6,
          },
          lg: {
            breakpoint: { max: 1200, min: 900 },
            items: 3,
          },
          md: {
            breakpoint: { max: 900, min: 600 },
            items: 2,
          },
          sm: {
            breakpoint: { max: 600, min: 0 },
            items: 1,
          },
        }}
        swipeable
        showDots
        centerMode={true}
        autoPlay
        autoPlaySpeed={2000}
        dotListClass=""
        //focusOnSelect={false}
        //partialVisible
      >
        {products.map((item: ProductDetailProps) => {
          return (
            <div
              className="flex object-fill w-full h-full my-auto"
              key={item.id}
            >
              <div
                className={
                  "relative w-full h-full mx-1 my-auto " +
                  (iOS() ? "hidden" : "sm:block hidden")
                }
              >
                <img
                  draggable={false}
                  src={item.images[0]}
                  className="block object-cover w-full h-full rounded-lg"
                  alt={item.name}
                />
                <div
                  className="absolute top-0 left-0 w-full h-full rounded-lg opacity-0 max-h-fit hover:opacity-100"
                  style={{ background: "rgba(0,0,0,0.5)" }}
                >
                  <div className="flex flex-col w-full h-full space-y-4 text-white">
                    <div className="w-full h-1/2">
                      <h3 className="w-full mx-auto mt-8 text-lg font-akrobat">
                        {item.name}
                      </h3>
                      {item.nameKor && (
                        <h3 className="w-full mx-auto text-lg font-akrobat">
                          {item.nameKor}
                        </h3>
                      )}
                      {item?.productInfo?.alcoholPercent &&
                        item?.productInfo?.productVolumeML && (
                          <span className="inline-block w-full my-4 text-sm">
                            Alcohol {item.productInfo.alcoholPercent}% |{" "}
                            {item.productInfo.productVolumeML}mL
                          </span>
                        )}
                    </div>
                    <div className="w-full m-auto space-y-2 h-1/2">
                      <button
                        className="block w-32 px-4 py-2 mx-auto mt-4 text-sm sm:mt-12 md:mt-4 lg:mt-8 sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                        onClick={() => {
                          navigate(`product/${item.id}`);
                        }}
                      >
                        View Product
                      </button>
                      <button
                            className={`block w-32 px-4 py-2 mx-auto text-sm sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat disabled:text-red-500 ${Number(item.inStock?.hamburg)<= 0 ? "hover:bg-tertiary" : "hover:bg-tertiaryhover"}`}
                            onClick={() => addProduct(item.id.toString())}
                            disabled={Number(item.inStock?.hamburg) <= 0}
                          >
                            {item.available &&
                            Number(item.inStock?.hamburg) > 0 ? (
                              <span>Add to Cart</span>
                            ) : (
                              <span>Out of Stock</span>
                            )}
                          </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "relative w-full h-full mx-1 my-auto " +
                  (iOS() ? "block" : "sm:hidden")
                }
              >
                <button
                  className="w-full h-full"
                  onClick={() => {
                    navigate(`product/${item.id}`);
                  }}
                >
                  <img
                    draggable={false}
                    src={item.images[0]}
                    className="block object-cover w-full h-full rounded-lg"
                    alt={item.name}
                  />
                </button>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductSlider;
