import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  isOnlyNumbers,
  isValidAddress,
  isValidEmail,
  isValidPhoneNumber,
} from "utils/helper";

import { Customer } from "types/customer";
import { Order } from "types/order";
import { Timestamp } from "firebase/firestore";
import { app } from "./firestore";

//export const createB2BOrder = async (order: Order) => {
//  const db = getFirestore(app);
//  const orderRef = collection(db, "orders");
//  await setDoc(doc(orderRef), {
//    order,
//  });
//};
const db = getFirestore(app);
const customerRef = collection(db, "customers");

export const getAllCustomers = async () => {
  const querySnapshot = await getDocs(customerRef);
  const allCustomers: any[] = [];
  querySnapshot.forEach((doc) => {
    allCustomers.push({ ...doc.data(), id: doc.id });
  });
  return allCustomers;
};

export const createCustomer = async (newCustomer: Customer) => {
  await setDoc(doc(customerRef), newCustomer);
  return true;
};

export const editCustomer = async (customer: Partial<Customer>) => {
  const {
    address,
    invoiceAddress,
    contactPerson,
    email,
    note,
    phoneNumber,
    name,
    shortName,
    id,
  } = customer;
  //const isCustomerValid =
  //  isValidAddress(address) &&
  //  isValidAddress(invoiceAddress) &&
  //  isValidEmail(email ?? "");
  ////&&
  ////isValidPhoneNumber(phoneNumber);
  //if (isCustomerValid && id) {
  if (id) {
    await updateDoc(doc(db, "customers", id), { ...customer });
    //await setDoc(doc(customerRef), customer);
    return true;
  } else {
    return false;
  }
};

export const deleteCustomer = async (customerId: string) => {
  await deleteDoc(doc(db, "customers", customerId));
};
