import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useEffect, useState } from "react";

import { BarChart } from "@mui/x-charts"
import { Order } from "types/order";
import { getDateString } from "utils/helper";

export const ByProducts = ({
    allOrders,
  allProducts,
}: {
    allOrders: any[];
    allProducts: any[];
}) => {
  const getDatasetByProducts = (products: any[], orders: Order[]) => {
    const dataset: any[] = products.map(({ name }) => ({
      total: 0,
      name,
      count: 0,
    }));
    orders
      .filter(({ isSample }) => !isSample)
      .forEach((order) => {
        const { created } = order;
        const month = created.toDate().getMonth() + 1;
        const year = created.toDate().getFullYear();
        const yymm = `${year}/${month}`;
        const customerName: string = order?.B2B
          ? order?.customerInfo?.name
          : "B2C";

        order.items.forEach((item) => {
          const currentDataset = dataset.find(({ name }) => name == item.name);
          currentDataset.count += Number(item?.quantity) ?? 0;
          currentDataset.total +=
            Number(item?.quantity) *
            (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          if (!Object.keys(currentDataset).includes(`${yymm}-count`)) {
            currentDataset[`${yymm}-count`] = Number(item?.quantity);
          } else {
            currentDataset[`${yymm}-count`] += Number(item?.quantity);
          }

          if (!Object.keys(currentDataset).includes(`${yymm}-total`)) {
            currentDataset[`${yymm}-total`] =
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          } else {
            currentDataset[`${yymm}-total`] +=
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          }

          if (!Object.keys(currentDataset).includes(`${customerName}-count`)) {
            currentDataset[`${customerName}-count`] = Number(item?.quantity);
          } else {
            currentDataset[`${customerName}-count`] += Number(item?.quantity);
          }

          if (!Object.keys(currentDataset).includes(`${customerName}-total`)) {
            currentDataset[`${customerName}-total`] =
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          } else {
            currentDataset[`${customerName}-total`] +=
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          }
        });
      });
    return dataset;
  };
  
  
      const columns: GridColDef[] = [{
        field: "name", headerName: "Product"
      }, { field: "total", headerName: "Total" }, {field:"orderCount", headerName: "Order Count"}, {field: "lastOrdered", headerName: "Last Ordered", renderCell: (params) => getDateString(params.row.lastOrdered) }]
    
  return (<div className="flex">

      <BarChart
        dataset={getDatasetByProducts(allProducts, allOrders)}
        layout="horizontal"
        height={500}
        margin={{ left: 100, bottom: 200 }}
        yAxis={[
          {
            scaleType: "band",
            dataKey: "name",
          },
        ]}
        series={[
          {
            dataKey: "total",
            valueFormatter: (value) =>
              `${Math.round(Number(value)).toLocaleString()}\u20AC`,
          },
        ]}
        slotProps={{
          legend: {
            direction: "row",
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
          },
        }}
        tooltip={{
          trigger: "item",
        }}
      grid={{ vertical: true }}
      className="w-2/3"
    />
    <div className="w-1/3">
      <DataGrid columns={columns} rows={getDatasetByProducts(allProducts, allOrders).map((e, i) => ({ name: e.name, id: i, total: Math.round(Number(e.total) * 100) / 100, orderCount: e.count }))}
                    rowSelection={false}
                    pageSizeOptions={[5, 10, 25, 50]}
                    initialState={{pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },}}
                />
      </div>
    </div>)
}

export default ByProducts