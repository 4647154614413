import "react-multi-carousel/lib/styles.css";

import { useEffect, useState } from "react";

import { Modal } from "@mui/material";
//import { Modal } from "@mui/material";
//https://www.belvini.de/kaapzicht-danie-steytler-little-brother-stellenbosch
import ProductSlider from "components/ProductSlider";
import collaboration_2 from "../assets/images/home/collaboration_2.png";
import logo from "../assets/images/navigation/logo_transparent.png";
import mainPage from "../assets/images/home/main_page.jpg";
//import promotion from "../assets/images/popups/promotion_1.png";
import sojuhalleSelection from "../assets/images/home/sojuhalle_selection.png";

export const Main = () => {
  const [promotionModalOpen, setPromotionModalOpen] = useState(false); // Set this to true to show promotion image
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  return (
    <div className="w-full h-full overflow-x-hidden bg-primary">
      {iOS() ? (
        <Modal
          className="max-w-6xl m-auto bg-white rounded-lg w-fit h-fit"
          open={promotionModalOpen}
          disableAutoFocus={true}
        >
          <div className="w-[49vh] h-[70vh] text-center bg-cover rounded-t-lg bg-[url('/src/assets/images/home/main_page.jpg')] bg-center flex mb-4">
            <button
              className="absolute px-2 text-xl right-5 top-5"
              onClick={() => setPromotionModalOpen(false)}
            >
              X
            </button>{" "}
            <div className="absolute w-full py-1 bg-black rounded-b-lg h-fit -bottom-4">
              <a
                className="text-white underline text-md font-akrobat"
                href="https://www.instagram.com/p/DEzdyH9oSyS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                target="_blank"
              >
                Click To See Details
              </a>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          className="m-auto bg-white rounded-lg h-fit w-fit"
          open={promotionModalOpen}
          disableAutoFocus={true}
        >
          <div className="flex-row mx-auto mb-4 overflow-scroll text-center bg-white rounded-t-lg h-fit w-fit">
            <button
              className="absolute px-2 text-xl right-5 top-5"
              onClick={() => setPromotionModalOpen(false)}
            >
              X
            </button>
            <div className="absolute w-full py-1 bg-black rounded-b-lg h-fit -bottom-4">
              <a
                className="text-white underline text-md font-akrobat"
                href="https://www.instagram.com/p/DEzdyH9oSyS/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                target="_blank"
              >
                Click To See Details
              </a>
            </div>
            {/*<img className="max-h-[80vh]" src={promotion} />*/}
          </div>
        </Modal>
      )}
      <div className="relative w-full h-full mt-24 max-h-fit top-30">
        <img
          src={mainPage}
          className="relative flex object-cover w-full h-[800px]"
        />
        <div className="absolute top-0 w-full h-full mt-16 tracking-widest text-white inlet-x-0 font-akrobat">
          <h3 className="mb-8 sm:mb-4 text-7xl sm:text-8xl md:text-9xl">
            SOJUHALLE
          </h3>
          <div className="text-sm sm:text-lg md:text-xl">
            Discover the world of Korean Traditional Wine and Spirits
          </div>
        </div>
      </div>
      <div className="relative w-full h-full top-30">
        <div className="flex w-full h-full mx-auto mt-12 lg:w-3/4 xl:w-3/4">
          <h3 className="w-full p-4 mx-auto mt-8 text-4xl text-cen3er lg:text-4xl font-akrobat">
            All Products
          </h3>
        </div>
        <div className="block mx-auto">
          <ProductSlider />
        </div>
      </div>
      <div className="w-full h-full mx-auto mt-24">
        <div className="w-full h-full pt-4 mx-auto mt-8 bg-secondary">
          <div className="hidden mx-auto md:block xl:w-3/4 lg:w-5/6 md:w-11/12">
            <div className="flex mt-4">
              <img
                src={logo}
                className="max-w-[200px] w-1/4 mx-4 my-auto h-1/4"
              />
              <div className="m-auto mx-12 mb-24">
                <h3 className="w-full p-2 mx-auto mt-2 text-3xl text-center lg:text-4xl font-akrobat">
                  About Us
                </h3>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  “Sojuhalle” (소주 할래) in Korean means “wanna grab a drink?”
                </span>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Sojuhalle is dedicated to bringing the rich heritage and
                  exquisite flavors of Korean traditional alcoholic beverages to
                  Europe, fostering cultural harmony through shared
                  appreciation. Renowned for their craftsmanship and
                  centuries-old brewing techniques, our curated selection of
                  soju, makgeolli, and other traditional Korean spirits embody
                  the essence of Korean culture in every sip.
                </span>
              </div>
            </div>
          </div>
          <div className="w-full pb-2 mx-auto md:hidden">
            <h3 className="p-4 mx-auto text-4xl text-center font-akrobat">
              About Us
            </h3>
            <div className="mt-4">
              <img src={logo} className="w-1/4 m-auto h-1/4" />
              <div className="w-full">
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  “Sojuhalle” (소주 할래) in Korean means “wanna grab a drink?”
                </span>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Sojuhalle is dedicated to bringing the rich heritage and
                  exquisite flavors of Korean traditional alcoholic beverages to
                  Europe, fostering cultural harmony through shared
                  appreciation. Renowned for their craftsmanship and
                  centuries-old brewing techniques, our curated selection of
                  soju, makgeolli, and other traditional Korean spirits embody
                  the essence of Korean culture in every sip.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full pt-4 mx-auto">
          <div className="hidden mx-auto md:block xl:w-3/4 lg:w-5/6">
            <div className="flex">
              <div className="m-auto mx-12">
                <h3 className="w-full p-2 mx-auto text-3xl text-center lg:text-4xl font-akrobat">
                  Our Selections
                </h3>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Embark on a journey with us as we venture into the heart of
                  Korea's local breweries and distilleries. During our visits,
                  we've uncovered extraordinary products crafted from local
                  ingredients, each steeped in captivating tales of
                  craftsmanship and heritage. At Sojuhalle, we take pride in our
                  collaboration with these local artisans and experts, who have
                  dedicated their lives to preserving and evolving their craft,
                  often spanning generations. We deeply respect the cultural
                  significance of these products, which not only contribute to
                  the preservation of Korea's heritage but also celebrate its
                  rich traditions.
                </span>
              </div>
              <img
                src={sojuhalleSelection}
                className="object-cover w-1/3 mx-4 my-auto"
              />
            </div>
          </div>
          <div className="w-full mx-auto md:hidden">
            <h3 className="p-4 mx-auto text-4xl text-center font-akrobat">
              Our Selections
            </h3>
            <div className="mt-4">
              <img
                src={sojuhalleSelection}
                className="object-cover w-1/2 m-auto h-1/2"
              />
              <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                Embark on a journey with us as we venture into the heart of
                Korea's local breweries and distilleries. During our visits,
                we've uncovered extraordinary products crafted from local
                ingredients, each steeped in captivating tales of craftsmanship
                and heritage. At Sojuhalle, we take pride in our collaboration
                with these local artisans and experts, who have dedicated their
                lives to preserving and evolving their craft, often spanning
                generations. We deeply respect the cultural significance of
                these products, which not only contribute to the preservation of
                Korea's heritage but also celebrate its rich traditions.
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-full pt-4 mx-auto mt-8 bg-secondary">
          <div className="hidden mx-auto md:block xl:w-3/4 lg:w-5/6 md:w-11/12">
            <div className="flex mt-4">
              <img src={collaboration_2} className="w-1/3 mx-4 my-auto h-1/3" />
              <div className="m-auto mx-12 mb-24">
                <h3 className="w-full p-2 mx-auto mt-2 text-3xl text-center lg:text-4xl font-akrobat">
                  Our Partners
                </h3>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Join us in our quest to explore the perfect harmony of flavors
                  by collaborating with food specialists, bartenders, and
                  renowned restaurants in Seoul and Berlin. Together, we strive
                  to elevate the overall dining and drinking experiences,
                  ensuring that every sip and bite is a symphony of taste
                  sensations. With the growing popularity of Korean cuisine,
                  there's an exciting opportunity to introduce Korean
                  traditional alcoholic beverages paired with authentic Korean
                  dishes, creating an unforgettable culinary journey for
                  enthusiasts and novices alike. At Spirits of Seoul, we're
                  passionate about sharing the spirit of Korea with the world,
                  one partnership at a time.
                </span>
              </div>
            </div>
          </div>
          <div className="w-full pb-2 mx-auto md:hidden">
            <h3 className="p-4 mx-auto text-4xl text-center font-akrobat">
              Our Partners
            </h3>
            <div className="mt-4">
              <img src={collaboration_2} className="w-1/3 m-auto h-1/3" />
              <div className="w-full">
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Join us in our quest to explore the perfect harmony of flavors
                  by collaborating with food specialists, bartenders, and
                  renowned restaurants in Seoul and Berlin. Together, we strive
                  to elevate the overall dining and drinking experiences,
                  ensuring that every sip and bite is a symphony of taste
                  sensations. With the growing popularity of Korean cuisine,
                  there's an exciting opportunity to introduce Korean
                  traditional alcoholic beverages paired with authentic Korean
                  dishes, creating an unforgettable culinary journey for
                  enthusiasts and novices alike. At Spirits of Seoul, we're
                  passionate about sharing the spirit of Korea with the world,
                  one partnership at a time.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="w-full h-full pt-4 pb-20 mx-auto mt-8 bg-secondary">
          <div className="hidden mx-auto md:block xl:w-3/4 lg:w-5/6 md:w-11/12">
            <div className="mt-4">
              <img
                className="object-cover w-1/2 m-auto h-1/2"
                src={collaboration_1}
              />
              <div className="m-auto xl:mx-8 lg:mx-6 md:mx-4">
                <h3 className="w-full p-2 mx-auto mt-2 text-3xl text-center lg:text-4xl font-akrobat">
                  Our Partners
                </h3>
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Join us in our quest to explore the perfect harmony of flavors
                  by collaborating with food specialists, bartenders, and
                  renowned restaurants in Seoul and Berlin. Together, we strive
                  to elevate the overall dining and drinking experiences,
                  ensuring that every sip and bite is a symphony of taste
                  sensations. With the growing popularity of Korean cuisine,
                  there's an exciting opportunity to introduce Korean
                  traditional alcoholic beverages paired with authentic Korean
                  dishes, creating an unforgettable culinary journey for
                  enthusiasts and novices alike. At Spirits of Seoul, we're
                  passionate about sharing the spirit of Korea with the world,
                  one partnership at a time.
                </span>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto md:hidden">
            <h3 className="p-4 mx-auto text-4xl text-center font-akrobat">
              Our Partners
            </h3>
            <div className="w-full mt-4">
              <div className="inline-flex w-full">
                <img className="object-cover w-1/2" src={collaboration_1} />
                <img className="object-cover w-1/2" src={collaboration_2} />
              </div>
              <div className="m-auto">
                <span className="block w-full p-4 text-lg text-justify md:text-xl font-akrobat">
                  Join us in our quest to explore the perfect harmony of flavors
                  by collaborating with food specialists, bartenders, and
                  renowned restaurants in Seoul and Berlin. Together, we strive
                  to elevate the overall dining and drinking experiences,
                  ensuring that every sip and bite is a symphony of taste
                  sensations. With the growing popularity of Korean cuisine,
                  there's an exciting opportunity to introduce Korean
                  traditional alcoholic beverages paired with authentic Korean
                  dishes, creating an unforgettable culinary journey for
                  enthusiasts and novices alike. At Spirits of Seoul, we're
                  passionate about sharing the spirit of Korea with the world,
                  one partnership at a time.
                </span>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
      {/*<div className="block h-24"></div>*/}
    </div>
  );
};

export default Main;

{
  /*<div className="absolute top-0 left-0 w-full h-full mx-auto mt-4 md:mt-4">
<div className="w-5/6 mx-auto lg:w-1/2 xl:1/3">
  <h3 className="text-xl font-bold text-center">Yangcheon</h3>
  <p className="mt-2 font-semibold text-center md:mt-4 text-md md:text-lg">
    This brewery is Yangcheon. This brewery is Yangcheon. This
    brewery is Yangcheon. This brewery is Yangcheon. This brewery is
    Yangcheon.
  </p>
</div>
</div>
<iframe
className="absolute inset-0 z-30 w-1/2 m-auto h-1/2 top-1/3 lg:w-1/3"
src="https://www.youtube.com/embed/IBRXkx0a0gI?si=gh6Z3LH6CuYcGwdZ"
allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share"
/>*/
}
