import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { Order } from "types/order";
import { Timestamp } from "firebase/firestore";
import { app } from "./firestore";
import { getAuth } from "@firebase/auth";

const db = getFirestore(app);
const auth = getAuth(app);

export const createShippingOrder = async (res: any) => {
  const paypalId = res.id;
  const items = res.purchase_units[0].items;
  const amount = res.purchase_units[0].amount;
  const email = res.purchase_units[0].custom_id.split("&")[0];
  const phoneNumber = res.purchase_units[0].custom_id.split("&")[1];
  const address = res.purchase_units[0].shipping.address;
  const name = res.purchase_units[0].shipping.name.full_name;

  const orderRef = collection(db, "orders");
  const productsRef = collection(db, "products");
  const docRef = doc(db, "orders", paypalId);
  // USE SCHEDULER TO FETCH B2C order status
  try {
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      await setDoc(doc(orderRef, paypalId), {
        customerInfo: {
          name,
          address,
          email,
          phoneNumber,
        },
        paypalId,
        items,
        amount,
        status: "Ordered",
        B2B: false,
        created: Timestamp.fromDate(new Date(res.create_time)),
      });
      // TEST BEFORE DEPLOYING
      //const q = query(
      //  productsRef,
      //  where(
      //    "name",
      //    "in",
      //    items.map(({ name }: any) => name),
      //  ),
      //);
      //const querySnapshot = await getDocs(q);
      //querySnapshot.forEach(async (document) => {
      //  const qtyToSubstract = items.find(
      //    ({ name }: any) =>
      //      name.toLowerCase() == document.data().name.toLowerCase(),
      //  ).quantity;
      //  const productRef = doc(db, "products", document.id);
      //  const locationKey = 'hamburg';
      //  const currentInStock = document.data().inStock;
      //  currentInStock[locationKey] =
      //    document.data().inStock[locationKey] - qtyToSubstract;
      //  currentInStock.total -= qtyToSubstract;
      //  await updateDoc(productRef, {
      //    inStock: currentInStock,
      //    lastOrdered: Timestamp.fromDate(new Date())
      //  });
      //});
    }
  } catch {
    console.error("Couldn't create order doc");
  }
};

export const createBusinessOrder = async (order: any) => {
  //const paypalId = res.id;

  const orderRef = collection(db, "orders");
  const lastInvoiceRef = doc(db, "record", "lastId");
  const recordSnap = await getDoc(lastInvoiceRef);
  const invoiceId = recordSnap?.data()?.invoice + 1;
  const orderIdRaw = recordSnap?.data()?.order + 1;
  const locationRef = doc(db, "locations", order.shippedFrom.id);
  const productsRef = collection(db, "products");
  const customerDoc = doc(db,"customers", order.customerInfo.id)

  const orderId =
    (order.isSample ? "S-" : "O-") +
    ("0000" + orderIdRaw).substring(("0000" + orderIdRaw).length - 4);
  await updateDoc(lastInvoiceRef, { invoice: invoiceId, order: orderIdRaw });
  const orderData: Order = {
    ...order,
    customer: customerDoc,
    status: "Ordered",
    shippedFrom: locationRef,
    B2B: true,
    created: Timestamp.fromDate(new Date()),
    invoiceId,
    orderId,
    createdBy: auth.currentUser?.displayName,
  };
  await updateDoc(customerDoc, {lastOrdered: Timestamp.fromDate(new Date())})
  await setDoc(doc(orderRef), orderData);

  const q = query(
    productsRef,
    where(
      "name",
      "in",
      order.items.map(({ name }: any) => name),
    ),
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (document) => {
    const qtyToSubstract = order.items.find(
      ({ name }: any) =>
        name.toLowerCase() == document.data().name.toLowerCase(),
    ).quantity;
    const productRef = doc(db, "products", document.id);
    const locationKey = order.shippedFrom.name.toLowerCase();
    const currentInStock = document.data().inStock;
    currentInStock[locationKey] =
      document.data().inStock[locationKey] - qtyToSubstract;
    currentInStock.total -= qtyToSubstract;
    await updateDoc(productRef, {
      inStock: currentInStock,
      lastOrdered: Timestamp.fromDate(new Date())
    });
  });
};

export const createShipping = async (orderId: string) => {
  await updateDoc(doc(db, "orders", orderId), {
    createShipment: true,
  });
  alert("Shipping Created");
};

export const voidShipment = async (orderId: string) => {
  await updateDoc(doc(db, "orders", orderId), {
    readyForVoid: true,
  });
};

export const updateStatus = async (
  orderId: string,
  newStatus: string,
  date: Timestamp,
) => {
  if (newStatus === "Completed")
    await updateDoc(doc(db, "orders", orderId), {
      completedDate: date,
      status: newStatus,
      updated: date,
    });
  if (newStatus === "Delivered")
    await updateDoc(doc(db, "orders", orderId), {
      deliveryDate: date,
      status: newStatus,
      updated: date,
    });
  if (newStatus === "Shipped")
    await updateDoc(doc(db, "orders", orderId), {
      shippedDate: date,
      status: newStatus,
      updated: date,
    });
};

export const getAllOrders = async () => {
  const orderRef = collection(db, "orders");
  const querySnapshot = await getDocs(orderRef);
  const allOrders: any[] = [];
  querySnapshot.forEach((doc) => {
    allOrders.push({ ...doc.data(), id: doc.id });
  });
  return allOrders;
};

export const updateWeights = async (orderId: string, weights: number[]) => {
  await updateDoc(doc(db, "orders", orderId), {
    weights,
  });
};

export const queryOrderByTime = async (startDate?: Date, endDate?: Date) => {
  const orderRef = collection(db, "orders")
  const fromDate = startDate ? new Date(startDate.setHours(0, 0, 0, 0)) : new Date('2021-01-01T00:00:00.000Z');
  const toDate = endDate ? new Date(endDate.setHours(23, 59, 59, 999)) : new Date();
  const q = query(
    orderRef,
    and(where("created", ">=", fromDate), where("created", "<=", toDate))
  );

  const querySnapshot = await getDocs(q);
  const queriedOrders: any[] = []
  querySnapshot.forEach((doc) => {
    queriedOrders.push({...doc.data(), id: doc.id})
  })
  return queriedOrders
}
