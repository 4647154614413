import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { BarChart } from "@mui/x-charts"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react";
import { Order } from "types/order";
import { getDateString } from "utils/helper";

export const ByCustomers = ({
    allOrders,
    allCustomers
}: {
    allOrders: any[];
    allCustomers: any[]
}) => {
    const [graphByCustomer, setGraphByCustomer] = useState("total");
  const getDatasetByCustomers = (customers: any, orders: Order[]) => {
    const uniqueDates: string[] = [];
    const dataset: any[] = [];
    orders
      .sort((a, b) => a.created.toMillis() - b.created.toMillis())
      .forEach((order) => {
        const orderTotal = order.items.reduce(
          (prev, curr) =>
            curr?.quantity *
              (Number(curr?.tax?.value) + Number(curr?.unit_amount?.value)) +
            prev,
          0,
        );
        const { created } = order;
        const month = created.toDate().getMonth() + 1;
        const year = created.toDate().getFullYear();
        const yymm = `${year}/${month}`;
        if (orderTotal > 0) {
          const customer = customers.find(
            (customer: any) => order.customerInfo.name == customer.name,
          );
          const customerName = order.B2B
            ? (customer?.shortName ?? customer?.name)
            : "B2C";
          const existingIndex = dataset.findIndex(
            ({ name }) => name == customerName,
          );
          if (existingIndex >= 0) {
            dataset[existingIndex].total += orderTotal;
            dataset[existingIndex].orderCount += 1;
            dataset[existingIndex].averageOrderTotal =
              dataset[existingIndex].total / dataset[existingIndex].orderCount;
            if (
              !Object.keys(dataset[existingIndex]).includes(`${yymm}-count`)
            ) {
              dataset[existingIndex][`${yymm}-count`] = 1;
            } else {
              dataset[existingIndex][`${yymm}-count`] += 1;
            }
            if (
              !Object.keys(dataset[existingIndex]).includes(`${yymm}-total`)
            ) {
              dataset[existingIndex][`${yymm}-total`] = orderTotal;
            } else {
              dataset[existingIndex][`${yymm}-total`] += orderTotal;
            }
            dataset[existingIndex][`${yymm}-averageOrder`] =
              dataset[existingIndex][`${yymm}-total`] /
              dataset[existingIndex][`${yymm}-count`];
          } else {
            dataset.push({
              name: customerName,
              total: orderTotal,
              orderCount: 1,
              averageOrderTotal: orderTotal,
              [`${yymm}-total`]: orderTotal,
              [`${yymm}-count`]: 1,
              [`${yymm}-averageOrder`]: orderTotal,
            });
          }
          if (!uniqueDates.includes(yymm)) uniqueDates.push(yymm);
        }
      });
      //return { dataset: dataset.sort((a, b) => b.total - a.total).slice(0,20), uniqueDates };
    return { dataset: dataset.sort((a, b) => b.total - a.total), uniqueDates };
  };
      const columns: GridColDef[] = [{
        field: "name", headerName: "Customer"
      }, { field: "total", headerName: "Total" }, {field:"average", headerName: "Average"}, {field:"orderCount", headerName: "Order Count"}, {field: "lastOrdered", headerName: "Last Ordered", renderCell: (params) => getDateString(params.row.lastOrdered) }]
    
    return ( <div className="inline-block w-full my-2">
        <RadioGroup
          row
          value={graphByCustomer}
          onChange={(e) => setGraphByCustomer(e.target.value)}
        >
          <FormControlLabel
            value="total"
            control={<Radio size="small" />}
            label="Total Order"
          />
          <FormControlLabel
            value="averageOrderTotal"
            control={<Radio size="small" />}
            label="Average Per 
    Order"
          />
          <FormControlLabel
            value="count"
            control={<Radio size="small" />}
            label="Order Count"
          />
      </RadioGroup>
      <div className="flex">
        <BarChart
          dataset={
            getDatasetByCustomers(allCustomers, allOrders).dataset
          }
          layout="horizontal"
          height={Math.max(
            500,
            getDatasetByCustomers(allCustomers, allOrders).dataset
              .length * 20,
          )}
          margin={{ left: 200 }}
          yAxis={[
            {
              scaleType: "band",
              dataKey: "name",
            },
          ]}
          series={
            graphByCustomer == "averageOrderTotal"
              ? [
                  {
                    dataKey: "averageOrderTotal",
                    valueFormatter: (value) =>
                      `${Math.round(
                        Number(value),
                      ).toLocaleString()}\u20AC`,
                  },
                ]
              : getDatasetByCustomers(
                  allCustomers,
                  allOrders,
                ).uniqueDates.map((e, i) => ({
                  dataKey: `${e}-${graphByCustomer}`,
                  stack: "stack",
                  label: e,
                  color: `hsl(${20 * i}, 40%, 50%)`,
                  valueFormatter: (value) =>
                    graphByCustomer.includes("total")
                      ? `${Math.round(
                          Number(value),
                        ).toLocaleString()}\u20AC`
                      : (value?.toString() ?? "0"),
                }))
          }
          tooltip={{
            trigger:
              graphByCustomer == "averageOrderTotal"
                ? "axis"
                : "item",
          }}
          grid={{ vertical: true }}
          className="w-2/3"
        />
        <div className="w-1/3">
                <DataGrid columns={columns} rows={getDatasetByCustomers(allCustomers, allOrders).dataset.map((e, i) => ({ name: e.name, id: i, total: Math.round(Number(e.total) * 100) / 100, average: Math.round(Number(e.averageOrderTotal) * 100) / 100, orderCount: e.orderCount, lastOrdered: allCustomers.find(({ name }) => name == e.name || name == e.shortName)?.lastOrdered || null }))}
                    rowSelection={false}
                    pageSizeOptions={[5, 10, 25, 50]}
                    initialState={{pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },}}
                /></div>
        </div>
      </div>)
}

export default ByCustomers