import kFoodLogo from "../assets/images/event/k-food_logo.png";
import koreanPartyFactoryLogo from "../assets/images/event/korean_party_factory_logo.png";
import logo from "../assets/images/navigation/logo_transparent.png";
import { useState } from "react";

export const EventView = () => {
  const [lang, setLang] = useState("en");
  return (
    <div className="flex flex-col w-5/6 h-full min-h-screen mx-auto font-akrobat">
      <div className="block w-full mx-auto mt-2">
        <div className="block w-full mx-auto mt-2 text-right">
          <button
            className={
              "px-1 " + (lang == "de" ? "text-blue-400" : "text-black")
            }
            onClick={() => setLang("de")}
          >
            DE
          </button>{" "}
          |{" "}
          <button
            className={
              "px-1 " + (lang == "en" ? "text-blue-400" : "text-black")
            }
            onClick={() => setLang("en")}
          >
            EN
          </button>{" "}
          |{" "}
          <button
            className={
              "px-1 " + (lang == "kr" ? "text-blue-400" : "text-black")
            }
            onClick={() => setLang("kr")}
          >
            KO
          </button>
        </div>
        <img src={logo} className="w-16 mx-auto" />
      </div>
      {lang == "de" ? (
        <div>
          <div className="flex flex-col w-full mt-2">
            <h2 className="mt-1 mb-12 text-2xl">
              Sojuhalle: Wie man zwei kostenlose Getränke und einen Rabatt
              erhält
            </h2>
            <ul className="flex flex-col ml-0 space-y-4">
              <li className="text-lg text-left">
                1. Folge uns auf{" "}
                <a
                  className="text-blue-400"
                  href="https://www.instagram.com/soju_halle/"
                  target="_blank"
                >
                  Instagram
                </a>
                .
              </li>
              <li className="text-lg text-left">
                2. Stell dich in die Reihe für die Farbe, die du erhalten hast.
                Hol dir ein{" "}
                <span className="font-bold">kostenloses Getränk</span>!
              </li>
              <li className="text-lg text-left">
                3. Fülle das{" "}
                <a
                  className="text-blue-400"
                  href="https://forms.gle/WdCA4A77KjJdBqib6"
                  target="_blank"
                >
                  Umfrageformular
                </a>{" "}
                aus und erhalte einen <span className="font-bold">10%</span>
                -Rabattcode!
              </li>
              <li className="text-lg text-left">
                4. Finde jemanden, der die{" "}
                <span className="font-bold">gleiche Farbe</span> wie deine Karte
                hat.
              </li>
              <li className="text-lg text-left">
                5. Kehre mit der Person zum Sojuhalle-Stand zurück und ihr beide
                bekommt ein{" "}
                <span className="font-bold">weiteres kostenloses Getränk</span>{" "}
                nach Wahl!
              </li>
            </ul>
          </div>

          <div className="flex flex-col w-full mt-12 text-left">
            <h2 className="text-xl">Finde mehr über deine Getränke heraus.</h2>
            <div className="grid grid-cols-2">
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Yakju (약주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/1"
                    target="_blank"
                  >
                    Nimome (니모메)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/3"
                    target="_blank"
                  >
                    Solsongju (솔송주)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/4"
                    target="_blank"
                  >
                    Yangchon Chungju (우렁이쌀)
                  </a>
                </li>
              </ul>
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Soju (소주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/10"
                    target="_blank"
                  >
                    Leegangju 25 (이강주 25)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/13"
                    target="_blank"
                  >
                    Damsoul (담솔)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12">
            <span>Visit </span>
            <a
              className="text-blue-400"
              target="_blank"
              href="https://sojuhalle.com/"
            >
              Sojuhalle
            </a>
            <div className="flex flex-col ">
              <div className="absolute bottom-0 left-0 flex flex-col w-screen bg-zinc-400">
                <span className="text-lg">Sponsored By:</span>
                <div className="inline-flex mx-auto">
                  <img className="max-w-[10rem] my-auto" src={kFoodLogo} />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : lang == "kr" ? (
        <div>
          <div className="flex flex-col w-full mt-2">
            <h2 className="mt-1 mb-12 text-2xl">
              Sojuhalle에서 Free Drink 두 잔과 할인 받는 법
            </h2>
            <ul className="flex flex-col ml-0 space-y-4">
              <li className="text-lg text-left">
                1. 소주할래{" "}
                <a
                  className="text-blue-400"
                  href="https://www.instagram.com/soju_halle/"
                  target="_blank"
                >
                  인스타그램
                </a>
                을 팔로우하세요.
              </li>
              <li className="text-lg text-left">
                2. 받은 색상에 맞춰 줄을 서세요.{" "}
                <span className="font-bold">무료 음료 한 잔</span>를 받으세요!
              </li>
              <li className="text-lg text-left">
                3.{" "}
                <a
                  className="text-blue-400"
                  href="https://forms.gle/WdCA4A77KjJdBqib6"
                  target="_blank"
                >
                  설문 조사
                </a>
                를 작성하고 <span className="font-bold">10%</span> 할인 코드를
                받으세요!
              </li>
              <li className="text-lg text-left">
                4. 본인 카드와 <span className="font-bold">같은 색의 카드</span>
                를 가진 사람을 찾으세요.
              </li>
              <li className="text-lg text-left">
                5. 함께 Sojuhalle 부스로 돌아오면, 두 사람 모두 원하는{" "}
                <span className="font-bold">무료 음료를 한 잔 더</span> 받게
                됩니다!
              </li>
            </ul>
          </div>

          <div className="flex flex-col w-full mt-12 text-left">
            <h2 className="text-xl">오늘의 전통주들:</h2>
            <div className="grid grid-cols-2">
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Yakju (약주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/1"
                    target="_blank"
                  >
                    Nimome (니모메)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/3"
                    target="_blank"
                  >
                    Solsongju (솔송주)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/4"
                    target="_blank"
                  >
                    Yangchon Chungju (우렁이쌀)
                  </a>
                </li>
              </ul>
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Soju (소주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/10"
                    target="_blank"
                  >
                    Leegangju 25 (이강주 25)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/13"
                    target="_blank"
                  >
                    Damsoul (담솔)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12">
            <span>Visit </span>
            <a
              className="text-blue-400"
              target="_blank"
              href="https://sojuhalle.com/"
            >
              Sojuhalle
            </a>
            <div className="flex flex-col ">
              <div className="absolute bottom-0 left-0 flex flex-col w-screen bg-zinc-400">
                <span className="text-lg">Sponsored By:</span>
                <div className="inline-flex mx-auto">
                  <img className="max-w-[10rem] my-auto" src={kFoodLogo} />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-col w-full mt-2">
            <h2 className="mt-1 mb-12 text-2xl">
              How to get two free drinks and a discount from Sojuhalle
            </h2>
            <ul className="flex flex-col ml-0 space-y-4">
              <li className="text-lg text-left">
                1. Follow us on{" "}
                <a
                  className="text-blue-400"
                  href="https://www.instagram.com/soju_halle/"
                  target="_blank"
                >
                  Instagram
                </a>
                .
              </li>
              <li className="text-lg text-left">
                2. Line up to the color you got. Get a{" "}
                <span className="font-bold">free drink</span>!
              </li>
              <li className="text-lg text-left">
                3. Fill out{" "}
                <a
                  className="text-blue-400"
                  href="https://forms.gle/WdCA4A77KjJdBqib6"
                  target="_blank"
                >
                  the survey
                </a>{" "}
                form and get a <span className="font-bold">10%</span> discount
                code!
              </li>
              <li className="text-lg text-left">
                4. Find someone who has the{" "}
                <span className="font-bold">same color</span> as your card.
              </li>
              <li className="text-lg text-left">
                5. Come back to the Sojuhalle booth with the person, and you
                both will get{" "}
                <span className="font-bold">another free drink</span> of your
                choice!
              </li>
            </ul>
          </div>
          <div className="flex flex-col w-full mt-12 text-left">
            <h2 className="text-xl">Find more about your drinks.</h2>
            <div className="grid grid-cols-2">
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Yakju (약주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/1"
                    target="_blank"
                  >
                    Nimome (니모메)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/3"
                    target="_blank"
                  >
                    Solsongju (솔송주)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/4"
                    target="_blank"
                  >
                    Yangchon Chungju (우렁이쌀)
                  </a>
                </li>
              </ul>
              <ul className="col-span-1 mt-2 space-y-2">
                <span>Soju (소주)</span>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/10"
                    target="_blank"
                  >
                    Leegangju 25 (이강주 25)
                  </a>
                </li>
                <li className="ml-1">
                  <a
                    className="text-blue-400"
                    href="https://sojuhalle.com/product/13"
                    target="_blank"
                  >
                    Damsoul (담솔)
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12">
            <span>Visit </span>
            <a
              className="text-blue-400"
              target="_blank"
              href="https://sojuhalle.com/"
            >
              Sojuhalle
            </a>
            <div className="flex flex-col ">
              <div className="absolute bottom-0 left-0 flex flex-col w-screen bg-zinc-400">
                <span className="text-lg">Sponsored By:</span>
                <div className="inline-flex mx-auto">
                  <img className="max-w-[10rem] my-auto" src={kFoodLogo} />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                  <img
                    className="max-w-[10rem] my-auto"
                    src={koreanPartyFactoryLogo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventView;
