import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import AGBView from "routes/AGBView";
import AdminView from "routes/AdminView";
import Cart from "components/cart/Cart";
import CartView from "routes/CartView";
import ContactView from "routes/ContactView";
import DatenschutzerklarungView from "routes/DatenschutzerklarungView";
import { EventView } from "routes/EventView";
import Footer from "components/footer/Footer";
import ImpressumView from "routes/ImpressumView";
import JugendschutzView from "routes/JugendschutzView";
import Main from "routes/Main";
import { Modal } from "@mui/material";
import NavigationBar from "components/NavigationBar";
import PartnersView from "routes/PartnersView";
import ProductDetail from "routes/ProductDetail";
import ProductView from "routes/ProductView";
import ReactGA from "react-ga4";
import { ReactNotifications } from "react-notifications-component";
import VersandmethodeView from "routes/VersandmethodeView";
import ZahlungsmethodeView from "routes/ZahlungsmethodeView";
import { store } from "store";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

type State = { id: string; qty: number };

const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};
function App() {
  if (iOS() && localStorage.getItem("ageConfirmed") !== "True") {
    if (
      confirm(
        "Website contains content of alcoholic beverages. Please verify you are over 18."
      )
    ) {
      window.localStorage.setItem("ageConfirmed", "True");
    } else window.history.go(-1);
  }

  const { pathname } = useLocation();

  const location = useLocation();
  const [ageModalOpen, setAgeModalOpen] = useState(
    localStorage.getItem("ageConfirmed") != "True" &&
      !pathname.includes("event") &&
      !pathname.includes("admin")
  );

  const [cookieModalOpen, setCookieModalOpen] = useState(
    localStorage.getItem("cookieAccepted") != "True" &&
      !pathname.includes("event") &&
      !pathname.includes("admin")
  );

  if (localStorage.getItem("cookieAccepted") == "True")
    ReactGA.initialize("G-MB0531S4P5");
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const items: State[] = useSelector((state: any) => state.cart);
  const totalQty = items.reduce((accum, current) => current.qty + accum, 0);

  const cartItems = JSON.parse(localStorage.getItem("cart-items") ?? "{}");
  if (Object.keys(cartItems).length && totalQty == 0) {
    store.dispatch({
      type: "cart/setCartFromStorage",
      payload: cartItems,
    });
    localStorage.removeItem("cart-items");
  }

  const handleClose = (_: any, r: any) => {
    if (r === "backdropClick") return;
    localStorage.setItem("ageConfirmed", "True");
    return setAgeModalOpen(false);
  };

  const acceptCookie = () => {
    localStorage.setItem("cookieAccepted", "True");
    ReactGA.initialize("G-MB0531S4P5");
    return setCookieModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("cookieAccepted") == "True")
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: location.pathname,
      });
    //ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="overflow-x-hidden text-center text-slate-900 bg-secondary font-akrobat max-w-screen">
      <ReactNotifications />
      <div
        className={`fixed top-0 z-50 w-2/3 h-full duration-500 ease-in-out bg-black opacity-50 left-1/3 max-w-screen sm:hidden ${
          isNavOpen ? "translate-x-0" : "translate-x-full"
        }`}
      />
      {!iOS() && (
        <Modal
          open={ageModalOpen}
          //hideBackdrop={iOS()}
          //disableEscapeKeyDown
          onClose={handleClose}
          disableAutoFocus={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="w-5/6 max-w-6xl m-auto bg-white rounded-lg h-fit sm:w-3/4 md:w-3/4 lg:w-2/3 xl:w-1/2"
        >
          <div className="flex-row w-full h-full mx-auto overflow-scroll text-center bg-white rounded-lg">
            {/*<div className="flex justify-end w-full">
            <Button onClick={handleClose}>X</Button>
          </div>*/}
            <h3 className="inline-block w-full my-4 text-2xl text-center md:mt-8 md:text-4xl font-akrobat">
              SOJUHALLE
            </h3>
            <p className="justify-center inline-block w-5/6 mx-auto text-center text-md md:text-lg h-fit">
              Website contains content of alcoholic beverages.
            </p>
            <p className="justify-center inline-block w-5/6 mx-auto text-center text-md h-fit md:text-lg">
              Please verify you are over 18.
            </p>
            <div className="w-full sm:flex h-fit justify-evenly">
              <div className="my-2 sm:my-8">
                <button
                  onClick={() => handleClose(null, "buttonClicked")}
                  className="w-2/3 px-6 py-2 m-auto text-sm text-white bg-black rounded-lg sm:w-full md:text-md"
                >
                  Yes, I am over 18.
                </button>
              </div>
              <div className="mb-4 sm:my-auto">
                <button
                  onClick={() => window.history.go(-1)}
                  className="w-2/3 px-6 py-2 m-auto text-sm text-white bg-black rounded-lg sm:w-full md:text-md"
                >
                  No, I am under 18.
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {!pathname.includes("event") && !pathname.includes("admin") && (
        <div>
          <NavigationBar
            isNavOpen={isNavOpen}
            setIsNavOpen={setIsNavOpen}
            setShowCart={setShowCart}
          />

          <Cart showCart={showCart} setShowCart={setShowCart} />
        </div>
      )}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/product" element={<ProductView />} />
        <Route path="/contact" element={<ContactView />} />
        <Route path="/partners" element={<PartnersView />} />
        <Route path="/cart" element={<CartView />} />
        <Route path="/impressum" element={<ImpressumView />} />
        <Route path="/agb" element={<AGBView />} />
        <Route
          path="/datenschutzerklarung"
          element={<DatenschutzerklarungView />}
        />
        <Route path="/jugendschutz" element={<JugendschutzView />} />
        <Route path="/zahlungsmethode" element={<ZahlungsmethodeView />} />
        <Route path="/versandmethode" element={<VersandmethodeView />} />
        <Route path="/admin" element={<AdminView />} />
        <Route path="/event" element={<EventView />} />
      </Routes>
      {cookieModalOpen && (
        <div className="fixed bottom-0 z-40 w-full h-32 bg-slate-300 lg:h-24">
          <div className="w-5/6 m-auto mt-4 text-sm sm:text-md">
            <p className="w-full h-full text-left font-akrobat lg:text-center">
              By tapping on "Accept", you agree to our and our partner's use of
              cookies and other technologies to processs your personal data to
              personalise your experience.
            </p>
            <button
              className="px-4 py-2 mx-2 my-1 text-white bg-black rounded-md"
              onClick={acceptCookie}
            >
              Accept All
            </button>
            <button
              className="px-4 py-2 mx-2 my-2 text-white bg-black rounded-md"
              onClick={() => {
                setCookieModalOpen(false);
              }}
            >
              Accept Only Necessary
            </button>
          </div>
        </div>
      )}
      {!pathname.includes("admin") && !pathname.includes("event") && <Footer />}
    </div>
  );
}

export default App;
