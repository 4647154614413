import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useEffect, useState } from "react";

import { BarChart } from "@mui/x-charts"
import { Order } from "types/order";
import { getDateString } from "utils/helper";

export const ByTrend = ({
    allOrders,
  allCustomers,
  fromDate,
  toDate
}: {
    allOrders: any[];
    allCustomers: any[];
    fromDate: Date
    toDate: Date
}) => {
    const getDataset = (customers: any, orders: Order[]) => {
      const dataset: {
        month: number;
        year: number;
        yymm: string;
        total: number;
        monthDate: Date;
        [key: string]: number | string | Date;
      }[] = [];
      const customerNames: string[] = [];
      const monthDates = [];
      let tempDate = new Date(fromDate);
      while (tempDate <= toDate) {
        monthDates.push(new Date(tempDate.getTime()));
        tempDate.setMonth(tempDate.getMonth() + 1);
      }
      monthDates.forEach((monthDate) => {
        const uniqueCustomerNames: string[] = [];
        const month = monthDate.getMonth() + 1;
        const year = monthDate.getFullYear();
        dataset.push({
          month,
          year,
          yymm: `${year}/${month}`,
          total: 0,
          monthDate,
        });
        orders
          .filter(
            (order) =>
              order.created.toDate().getMonth() + 1 == month &&
              order.created.toDate().getFullYear() == year,
          )
          .forEach((order) => {
            const orderTotal = order.items.reduce(
              (prev, curr) =>
                curr?.quantity *
                  (Number(curr?.tax?.value) + Number(curr?.unit_amount?.value)) +
                prev,
              0,
            );
            const currentData = dataset.find(
              (e) => e.month == month && e.year == year,
            );
            if (
              orderTotal > 0 &&
              currentData &&
              order.created.toDate() > fromDate &&
              order.created.toDate() < toDate
            ) {
              const customer = customers.find(
                (customer: any) => order.customerInfo.name == customer.name,
              );
              const customerName: string = order?.B2B
                ? (customer?.shortName ?? customer?.name)
                : "B2C";
  
              currentData.total += orderTotal;
              if (uniqueCustomerNames.includes(customerName))
                currentData[customerName] += orderTotal;
              else {
                currentData[customerName] = orderTotal;
                uniqueCustomerNames.push(customerName);
              }
              if (!customerNames.includes(customerName))
                customerNames.push(customerName);
            }
          });
      });
      console.log(dataset)
      return {
        dataset,
        customerNames,
      };
    };
  
  
      const columns: GridColDef[] = [{
        field: "name", headerName: "Product"
      }, { field: "total", headerName: "Total" }, {field:"average", headerName: "Average"}, {field:"orderCount", headerName: "Order Count"}, {field: "lastOrdered", headerName: "Last Ordered", renderCell: (params) => getDateString(params.row.lastOrdered) }]
    
    return (                <div>
      <BarChart
        dataset={getDataset(allCustomers, allOrders).dataset}
        layout="vertical"
        height={500}
        margin={{ left: 100, bottom: 100 }}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "yymm",
          },
        ]}
        series={getDataset(
          allCustomers,
          allOrders,
        ).customerNames.map((e, i) => ({
          dataKey: e,
          stack: "stack",
          label: e,
          color: `hsl(${20 * i}, 40%, 50%)`,
          valueFormatter: (value) =>
            `${Math.round(Number(value)).toLocaleString()}\u20AC`,
        }))}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        tooltip={{
          trigger: "item",
        }}
        grid={{ vertical: true }}
      />
    </div>)
}

export default ByTrend