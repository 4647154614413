import { BrowseBy, ProductInfo } from "assets/Content";
import { useEffect, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImageGallery from "react-image-gallery";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotificationContent from "components/NotificationContent";
import { ProductDetailProps } from "assets/Content";
import React from "react";
import { Store } from "react-notifications-component";
import { getPerLiterPrice } from "utils/helper";
import { store } from "store";
import { useFirestore } from "components/FirestoreContext";
import { useParams } from "react-router-dom";

enum CollapsibleOpen {
  howToEnjoy = "howToEnjoy",
  howToPair = "howToPair",
  factSheet = "factSheet",
}

export const ProductDetail = () => {
  const [expanded, setExpanded] = useState<CollapsibleOpen | null>(null);
  const [qty, setQty] = useState<number>(1);
  const { products, loading } = useFirestore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  const content = products.find((e) => e.id.toString() == id);

  const addProduct = (productId?: string) => {
    if (productId)
      store.dispatch({
        type: "cart/addNewProduct",
        payload: { id: productId, qty },
      });
    const productName = products.filter(({ id }: any) => id == productId)[0]
      .name;
    Store.addNotification({
      content: NotificationContent(productName, qty),
      //message: "Product Added",
      type: "default",
      //insert: "top",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      },
    });
  };

  const getImages = (content: ProductDetailProps) =>
    content?.images.map((image: string) => ({
      original: image,
      thumbnail: image,
      thumbnailClass: "float-left",
    }));

  const lineBreak = (text: string | undefined) =>
    text
      ? text.split("\n").map((line: string, index: number) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))
      : "";

  const getFactSheetInfo = (info: ProductDetailProps["productInfo"]) => {
    let drinkCategory;
    switch (info?.category) {
      case "Fermented":
        drinkCategory = "Wine/Fermented Beverages";
        break;
      case "Liqueurs":
        drinkCategory = "liqueurs/Alcoholic Beverages";
        break;
      case "Spirits":
        drinkCategory = "Spirits/Alcoholic Beverages";
        break;
      default:
        drinkCategory = "";
        break;
    }
    const text = `${drinkCategory}, ${info?.productVolumeML?.toFixed(
      0
    )}ml, Alc. ${info?.alcoholPercent}% Vol`;
    return text;
  };

  const productStructuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    brand: {
      "@type": "Brand",
      name: content?.productInfo?.producer,
    },
    category: "Alcoholic Beverage",
    countryOfOrigin:
      content?.productInfo?.region?.split(",")[1] ?? "South Korea",
    keywords: `Korean Traditional Drink, Alcoholic Beverage, Soju, ${
      content?.name
    }, ${content?.nameKor}, ${
      content?.productInfo?.category
    }, ${content?.productInfo?.ingredients?.join(", ")}, ${
      content?.productInfo?.type
    }`,
    description: content?.description?.desc,
    name: content?.name,
    url: `https://sojuhalle.com/product/${content?.id}`,
    image: content?.images?.length
      ? `https://sojuhalle.com${getImages(content)[0].original}`
      : "",
    offers: {
      "@type": "Offer",
      price: content?.price,
      priceCurrency: "EUR",
      availability:
        content?.inStock?.hamburg && content?.inStock?.hamburg > 0
          ? "https://schema.org/InStock"
          : "https://schema.org/OutOfStock",
      seller: {
        "@type": "Brand",
        name: "Soju Halle",
      },
    },
  };
  if (loading) return null;
  return (
    <div className="flex w-full mx-auto text-neutral-900 font-extralight font-akrobat">
      {/* Only apply structured data for products with even number id for testing */}
      {Number(id) % 2 == 0 && content?.available && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(productStructuredData),
          }}
        />
      )}
      <div className="relative w-full h-full mx-auto mb-24 mt-36 lg:w-3/4 md:w-5/6">
        <div className="flex w-full h-full">
          <div className="inline-flex mx-auto mb-12 w-fit">
            <h3 className="h-full text-3xl w-fit md:text-4xl">
              {content?.name} ({content?.nameKor})
            </h3>
          </div>
        </div>
        <div className="w-full mx-auto md:flex">
          <div className="w-5/6 h-full mx-auto md:mr-8 xl:w-1/3 md:w-1/2 min-h-fit">
            {content && content.images.length > 0 && (
              <ImageGallery
                items={getImages(content)}
                showFullscreenButton={false}
                showNav={false}
                showPlayButton={false}
                lazyLoad={true}
              />
            )}
          </div>
          <div className="block w-11/12 mx-auto md:w-1/2">
            <div className="w-full mx-auto mt-4">
              <div className="grid w-full grid-cols-2">
                <div className="col-span-1">
                  <div className="w-full">
                    <div className="inline-flex w-full h-fit">
                      <div className="float-left mt-1 ">
                        <span className="text-4xl">
                          {content?.price?.toString().replace(".", ",") +
                            "\u20AC"}
                        </span>
                      </div>
                      {content?.prevPrice ? (
                        <div className="flex flex-col float-right w-fit h-fit">
                          <span className="px-1 ml-1 bg-red-300 rounded-md text-md w-fit h-fit">
                            {(
                              ((Number(content?.prevPrice) -
                                Number(content?.price)) *
                                100) /
                              Number(content?.prevPrice)
                            ).toFixed(0)}
                            % OFF
                          </span>
                          <span className="pl-2 text-gray-500 line-through text-md w-fit h-fit">
                            {content?.prevPrice?.toFixed(2).replace(".", ",") +
                              "\u20AC"}
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )}
                      {Number(content?.inStock?.hamburg) > 0 &&
                        Number(content?.inStock?.hamburg) < 10 && (
                          <span className="h-full m-0 mt-6 ml-2 text-gray-500 align-middle text-md w-fit">
                            Only {content?.inStock?.hamburg} left in stock
                          </span>
                        )}
                    </div>
                    <span className="flex w-full text-xs">
                      Tax (VAT 19%) Included
                    </span>
                  </div>
                  <span className="flex w-full text-2xl opacity-50">
                    {getPerLiterPrice(
                      content?.price,
                      content?.productInfo?.productVolumeML
                    )}
                  </span>
                </div>
                {content?.productInfo?.type !== BrowseBy.set && (
                  <div className="w-full col-span-1">
                    <span className="block w-full mx-auto ml-0 text-lg text-right">
                      <LocationOnIcon /> {content?.productInfo?.producer}
                    </span>
                    <span className="block w-full mx-auto mr-0 text-lg text-right">
                      {content?.productInfo?.productVolumeML}mL (
                      {content?.productInfo?.alcoholPercent}% Vol)
                    </span>
                  </div>
                )}
              </div>
              <div className="inline-flex justify-between w-full my-4">
                <div className="inline-flex m-auto mx-2 min-w-fit h-fit">
                  <span className="mr-4">Quantity:</span>
                  <div className="inline-flex w-24 m-auto border">
                    <button
                      className="w-6 m-auto text-xl text-black rounded-md"
                      onClick={() => {
                        if (qty > 1) setQty(qty - 1);
                      }}
                    >
                      -
                    </button>
                    <span className="flex mx-2 my-auto">{qty}</span>
                    <button
                      className="w-6 m-auto text-xl text-black rounded-md"
                      onClick={() => {
                        setQty(qty + 1);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex w-fit">
                  <button
                    className="block w-32 px-4 py-2 mx-auto text-sm sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover hover:disabled:bg-transparent disabled:bg-transparent disabled:text-red-500"
                    onClick={() => addProduct(content?.id.toString())}
                    disabled={Number(content?.inStock?.hamburg) <= 0}
                  >
                    {content?.available &&
                    Number(content?.inStock?.hamburg) > 0 ? (
                      <span>Add to Cart</span>
                    ) : (
                      <span className="text-lg">Out of Stock</span>
                    )}
                  </button>
                </div>
              </div>
              <p className="my-4 text-lg text-left">{content?.desc}</p>
              <div className="block mt-8 mb-12 space-y-4">
                <div className="w-full border-b border-black">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.howToEnjoy
                          ? null
                          : CollapsibleOpen.howToEnjoy
                      )
                    }
                  >
                    <h3>How To Enjoy</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.howToEnjoy
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.howToEnjoy
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <p className="w-full h-full p-2 mb-6 text-left text-wrap">
                      {lineBreak(content?.howTo?.enjoy)}
                    </p>
                  </div>
                </div>
                <div className="w-full border-b border-black">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.howToPair
                          ? null
                          : CollapsibleOpen.howToPair
                      )
                    }
                  >
                    <h3>How To Pair</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.howToPair
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.howToPair
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <p className="w-full h-full p-2 mb-6 text-left text-wrap">
                      {lineBreak(content?.howTo?.pair)}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <button
                    className="flex justify-between w-full px-2"
                    onClick={() =>
                      setExpanded(
                        expanded == CollapsibleOpen.factSheet
                          ? null
                          : CollapsibleOpen.factSheet
                      )
                    }
                  >
                    <h3>Fact Sheet</h3>
                    <span
                      className={`${
                        expanded == CollapsibleOpen.factSheet
                          ? "rotate-0"
                          : "-rotate-180"
                      } duration-750 transition-all transform`}
                    >
                      <ExpandLessIcon />
                    </span>
                  </button>
                  <div
                    className={`${
                      expanded == CollapsibleOpen.factSheet
                        ? "max-h-96"
                        : "max-h-0"
                    } duration-750 ease-in-out w-fit overflow-hidden p-2 transition-all`}
                  >
                    <div className="w-full h-full p-2 mb-6 text-left text-wrap">
                      <span className="flex w-full font-bold">
                        Ingredients:
                      </span>
                      {content?.productInfo?.type === BrowseBy.set ? (
                        content?.setProductInfo?.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {info.ingredients?.join(", ")}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {content?.productInfo?.ingredients?.join(", ")}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        Information:
                      </span>
                      {content?.productInfo?.type === BrowseBy.set ? (
                        content?.setProductInfo?.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {getFactSheetInfo(info)}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {getFactSheetInfo(content?.productInfo)}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        Brewery:
                      </span>
                      {content?.productInfo?.type === BrowseBy.set ? (
                        content?.setProductInfo?.map((info: ProductInfo) => (
                          <span className="flex w-full">
                            {info.name} - {info.producer}, {info.region}
                          </span>
                        ))
                      ) : (
                        <span className="flex w-full">
                          {content?.productInfo?.producer},{" "}
                          {content?.productInfo?.region}
                        </span>
                      )}
                      <span className="flex w-full mt-2 font-bold">
                        {content?.name.includes("Yunguna")
                          ? "Distributor"
                          : "Importer"}
                      </span>
                      <span className="flex w-full">
                        Soju Halle GmbH, An den Pappeln 4a, 21521 Wohltorf,
                        Germany
                      </span>
                      <span className="flex w-full mt-4 text-xs">
                        * Images and labels may differ from the online images.
                        Although we strive to provide accurate representations,
                        variations may exist. Rest assured, the quality and
                        specifications remain consistent with our high
                        standards.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/*{content?.taste.sweet * content?.taste.rich * content?.taste.flavor >
                0 && (
                <div className="w-11/12 mx-auto my-8">
                  <TasteSlider
                    degree={content?.taste.sweet}
                    textMax="Sweet"
                    textMin="Dry"
                  />
                  <TasteSlider
                    degree={content?.taste.rich}
                    textMax="Rich"
                    textMin="Light"
                  />
                  <TasteSlider
                    degree={content?.taste.flavor}
                    textMax="Flavor"
                    textMin="Neat"
                  />
                </div>
              )}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
