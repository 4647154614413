import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  createCustomer,
  deleteCustomer,
  editCustomer,
  getAllCustomers,
} from "firestore/customers";
import {
  getDateString,
  getFullAddress,
  isOnlyNumbers,
  isValidAddress,
  isValidEmail,
  isValidLetters,
  isValidPhoneNumber,
  removeSpecialCharacters,
} from "utils/helper";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import { Modal, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ChangeEvent, useEffect, useState } from "react";
import { Address, type Customer } from "types/customer";
import { ExcelDownloadButton } from "components/ExcelDownloadButton";
import StringField from "components/Inputs/StringField";
import PhoneNumberField from "components/Inputs/PhoneNumberField";
import AddressFields from "components/Inputs/AddressFields";

export const Customers = ({
  allCustomers,
  setAllCustomers,
}: {
  allCustomers: Customer[];
  setAllCustomers: any;
}) => {
  const emptyAddress: Address = {
    address_line_1: "",
    address_line_2: "",
    admin_area_1: "",
    country_code: "DE",
    postal_code: "",
  };
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<string>("");
  const [invoicePerson, setInvoicePerson] = useState<string>("");
  const [address, setAddress] = useState<Address>(emptyAddress);
  const [invoiceAddress, setInvoiceAddress] = useState<Address>(emptyAddress);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [shortName, setShortName] = useState<string>("");
  const [hasDifferentAddress, setHasDifferentAddress] =
    useState<boolean>(false);
  const [editCustomerId, setEditCustomerId] = useState<
    string | undefined | null
  >(null);
  const [errorMsgs, setErrorMsgs] = useState<string[]>([]);

  const initializeCustomerFields = (customer?: Customer) => {
    if (allCustomers.length > 0) {
      const highestAccountNumber = allCustomers
        .map(({ accountNumber }) => accountNumber)
        .sort((a, b) => Number(b) - Number(a))[0];
      setAccountNumber(
        customer?.accountNumber ?? String(Number(highestAccountNumber) + 1)
      );
    } else {
      setAccountNumber(customer?.accountNumber ?? "");
    }
    setAccountName(customer?.name ?? "");
    setContactPerson(customer?.contactPerson ?? "");
    setInvoicePerson(customer?.invoicePerson ?? "");
    setAddress(customer?.address ?? emptyAddress);
    setInvoiceAddress(customer?.invoiceAddress ?? emptyAddress);
    setPhoneNumber(customer?.phoneNumber ?? "");
    setEmail(customer?.email ?? "");
    setNote(customer?.note ?? "");
    setHasDifferentAddress(
      customer?.invoiceAddress?.address_line_1 !==
        customer?.address?.address_line_1
        ? true
        : false
    );
    setShortName(customer?.shortName ?? "");
    setErrorMsgs([]);
  };

  const handleCustomerCreate = () => {
    if (!isDuplicateAccountNumber() && !hasAnyError()) {
      createCustomer({
        accountNumber,
        address,
        invoiceAddress: hasDifferentAddress ? invoiceAddress : address,
        contactPerson,
        email,
        note,
        phoneNumber,
        name: accountName,
        shortName,
        invoicePerson,
      }).then((res) => {
        if (res) {
          initializeCustomerFields();
          alert("Customer Created");
        } else {
          alert("Invalid or Missing Input");
        }
        getAllCustomers().then((res) => {
          setAllCustomers([...res]);
        });
      });
    } else {
      alert("Invalid or Missing Input");
    }
  };

  const handleCustomerEdit = () => {
    if (editCustomerId && !hasAnyError())
      editCustomer({
        address,
        invoiceAddress: hasDifferentAddress ? invoiceAddress : address,
        contactPerson,
        email,
        note,
        phoneNumber,
        name: accountName,
        shortName,
        invoicePerson,
        id: editCustomerId,
      }).then((res) => {
        if (res) {
          setEditCustomerId(null);
          initializeCustomerFields();
          alert("Customer Updated");
        } else {
          alert("Invalid or Missing Input");
        }
        getAllCustomers().then((res) => {
          setAllCustomers([...res]);
        });
      });
    else alert("Invalid or Missing Input");
  };

  const customerEditButton = (params: any) => (
    <button
      onClick={() => {
        const customer = allCustomers.find(({ id }) => params?.row?.id == id);
        if (customer) {
          initializeCustomerFields(customer);
          setEditCustomerId(customer?.id);
        }
      }}
    >
      <EditIcon />
    </button>
  );

  const customerDeleteButton = (params: any) => (
    <button
      onClick={() => {
        if (confirm(`Are you sure to delete ${params.row.businessName}`))
          deleteCustomer(params.row.id).then(() => {
            getAllCustomers().then(async (res) => {
              setAllCustomers([...res]);
            });
          });
      }}
    >
      X
    </button>
  );

  const hasAnyError = () => {
    const newErrorMsgs = [];
    if (!isOnlyNumbers(accountNumber))
      newErrorMsgs.push(
        "Invalid/Missing Account Number (should be only numbers)"
      );
    if (!accountName || accountName.length == 0)
      newErrorMsgs.push("Invalid/Missing Business Name");
    if (!isValidLetters(contactPerson))
      newErrorMsgs.push("Invalid/Missing Contect Person");
    if (!isValidPhoneNumber(phoneNumber))
      newErrorMsgs.push("Invalid/Missing Phone Number");
    if (!isValidEmail(email)) newErrorMsgs.push("Invalid/Missing Email");
    if (!isValidAddress(address)) newErrorMsgs.push("Invalid/Missing Address");
    if (hasDifferentAddress && !isValidAddress(invoiceAddress))
      newErrorMsgs.push("Invalid/Missing Invoice Address");
    setErrorMsgs(newErrorMsgs);
    return newErrorMsgs.length > 0;
  };

  const isDuplicateAccountNumber = () => {
    const newErrorMsgs = [];
    if (
      allCustomers
        .map(({ accountNumber }) => accountNumber)
        .includes(accountNumber)
    ) {
      newErrorMsgs.push("Duplicate Account Number");
    }
    setErrorMsgs(newErrorMsgs);
    return newErrorMsgs.length > 0;
  };

  const columns: GridColDef[] = [
    { field: "accountNumber", headerName: "Account", minWidth: 80 },
    { field: "shortName", headerName: "Nickname", minWidth: 200 },
    { field: "businessName", headerName: "Business Name", minWidth: 200 },
    {
      field: "lastOrdered",
      headerName: "Last Ordered",
      minWidth: 100,
      renderCell: (params) => getDateString(params.row.lastOrdered),
      sortable: true,
    },
    { field: "contactPerson", headerName: "Contact Person", minWidth: 150 },
    { field: "address", headerName: "Address", sortable: false, minWidth: 300 },
    {
      field: "invoiceAddress",
      headerName: "Invoice Address",
      sortable: false,
      minWidth: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      minWidth: 140,
    },
    { field: "email", headerName: "Email", sortable: false, minWidth: 150 },
    { field: "note", headerName: "Note", sortable: false, minWidth: 150 },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      minWidth: 30,
      width: 35,
      renderCell: customerEditButton,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      minWidth: 30,
      width: 35,
      renderCell: customerDeleteButton,
    },
  ];

  const rows = allCustomers.map((customer) => ({
    id: customer.id,
    accountNumber: customer.accountNumber,
    lastOrdered: customer.lastOrdered,
    shortName: customer?.shortName ?? "",
    businessName: customer.name,
    contactPerson: customer.contactPerson,
    address: getFullAddress(customer.address),
    invoiceAddress: customer?.invoiceAddress
      ? getFullAddress(customer.invoiceAddress, customer?.invoicePerson)
      : getFullAddress(customer.address),
    phoneNumber: customer.phoneNumber,
    email: customer.email,
    note: customer?.note,
  }));

  useEffect(() => {
    if (allCustomers.length > 0) {
      initializeCustomerFields();
    }
  }, [allCustomers]);

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <Modal
        open={editCustomerId != undefined && editCustomerId != null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-3/4 m-auto bg-white rounded-lg h-fit"
      >
        <div className="w-full bg-white rounded-lg h-fit">
          <div className="w-3/4 mx-auto text-center h-fit">
            <h3 className="inline-block w-full mt-8 text-3xl text-center md:text-4xl font-akrobat">
              Edit{" "}
              {allCustomers.find(({ id }) => editCustomerId == id)?.shortName ??
                allCustomers.find(({ id }) => editCustomerId == id)?.name}
            </h3>
            <div className="w-full h-full pt-4 border-t-2">
              <div className="flex w-full h-full">
                <div className="flex-row w-1/2 mx-2 h-fit">
                  <div className="inline-flex w-full my-1">
                    <span className="w-1/3 my-auto text-left">
                      Account Number
                    </span>
                    <span className="w-2/3 my-auto text-left">
                      {accountNumber}
                    </span>
                  </div>
                  <div className="inline-flex w-full my-1">
                    <span className="w-1/3 my-auto text-left">
                      Business Name
                    </span>
                    <span className="w-2/3 my-auto text-left">
                      {accountName}
                    </span>
                  </div>
                  <StringField
                    value={contactPerson}
                    label={"Contact Person"}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setContactPerson(e.target.value)
                    }
                  />
                  <StringField
                    label={"Nickname (Optional)"}
                    value={shortName}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setShortName(e.target.value)
                    }
                  />
                  <div className="mt-12">
                    <AddressFields
                      address={address}
                      label="Shipping Address"
                      setAddress={setAddress}
                    />
                  </div>
                </div>
                <div className="flex-row w-1/2 mx-2 h-fit">
                  <PhoneNumberField
                    value={phoneNumber}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPhoneNumber(removeSpecialCharacters(e.target.value))
                    }
                  />
                  <StringField
                    value={email}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    label="Email"
                    handleError={!isValidEmail(email) && email.length > 0}
                  />
                  <StringField
                    value={note}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNote(e.target.value)
                    }
                    label="Note (optional)"
                    handleError={false}
                  />
                  <StringField
                    value={invoicePerson}
                    handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setInvoicePerson(e.target.value)
                    }
                    label="Invoice Person (optional)"
                  />

                  {hasDifferentAddress && (
                    <AddressFields
                      address={invoiceAddress}
                      label="Invoice Address (if different than Shipping Address)"
                      setAddress={setInvoiceAddress}
                    />
                  )}
                  <div className="flex my-auto">
                    <Checkbox
                      checked={hasDifferentAddress}
                      onChange={() =>
                        setHasDifferentAddress(!hasDifferentAddress)
                      }
                      className="my-auto"
                    />
                    <span className="my-auto">
                      Invoice address is different from shipping address
                    </span>
                  </div>
                </div>
              </div>
              <div className="inline-flex gap-x-12">
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={handleCustomerEdit}
                >
                  Edit Customer
                </button>
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={() => {
                    initializeCustomerFields();
                    setEditCustomerId(null);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="flex flex-col pb-8 mr-4">
                {errorMsgs.map((e) => (
                  <div>
                    <span className="float-right text-red-500">{e}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Customers</h3>
        <div className="flex w-full h-full mx-12">
          <Accordion className="w-11/12 h-full">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <div className="text-seconadry">Create Customer</div>
            </AccordionSummary>
            <AccordionDetails
              hidden={editCustomerId != null && editCustomerId != undefined}
            >
              <div className="w-full h-full pt-4 border-t-2">
                <div className="flex w-full h-full">
                  <div className="flex-row w-1/2 mx-2 h-fit">
                    <div className="inline-flex w-full my-1">
                      <span className="w-1/3 my-auto text-left">
                        Account Number
                      </span>
                      <TextField
                        type="string"
                        size="small"
                        className="w-2/3 h-full"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        error={!isOnlyNumbers(accountNumber)}
                      />
                    </div>
                    <StringField
                      value={accountName}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setAccountName(e.target.value)
                      }
                      label="Business Name"
                    />
                    <StringField
                      value={shortName}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setShortName(e.target.value)
                      }
                      label="Nickname (optional)"
                    />
                    <StringField
                      value={contactPerson}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setContactPerson(e.target.value)
                      }
                      label="Contact Person"
                    />
                    <div className="mt-12">
                      <AddressFields
                        address={address}
                        label={"Shipping Addresss"}
                        setAddress={setAddress}
                      />
                    </div>
                  </div>
                  <div className="flex-row w-1/2 mx-2 h-fit">
                    <PhoneNumberField
                      value={phoneNumber}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhoneNumber(removeSpecialCharacters(e.target.value))
                      }
                    />
                    <StringField
                      value={email}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      label="Email"
                      handleError={!isValidEmail(email) && email.length > 0}
                    />
                    <StringField
                      value={note}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setNote(e.target.value)
                      }
                      label="Note (optional)"
                    />
                    <StringField
                      value={invoicePerson}
                      handleOnChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setInvoicePerson(e.target.value)
                      }
                      label="Invoice Person (optional)"
                    />
                    <div className="mt-12">
                      {hasDifferentAddress && (
                        <AddressFields
                          address={invoiceAddress}
                          label="Invoice Address (if different than Shipping Address)"
                          setAddress={setInvoiceAddress}
                        />
                      )}
                    </div>
                    <div className="flex my-auto">
                      <Checkbox
                        checked={hasDifferentAddress}
                        onChange={() =>
                          setHasDifferentAddress(!hasDifferentAddress)
                        }
                        className="my-auto"
                      />
                      <span className="my-auto">
                        Invoice address is different from shipping address
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className="px-4 py-2 mt-8 rounded-lg bg-tertiary text-primary"
                  onClick={handleCustomerCreate}
                >
                  Create Customer
                </button>
                {errorMsgs.length > 0 && (
                  <div className="flex flex-col mb-4 mr-4">
                    {errorMsgs.map((e) => (
                      <div>
                        <span className="float-right text-red-500">{e}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="flex w-full my-2 h-fit xl:w-11/12 xl:mx-12">
          <ExcelDownloadButton rows={rows} fileName="customers" />
        </div>
        <div className="w-full h-full mt-4 xl:w-11/12 xl:mx-12">
          <DataGrid
            rows={rows}
            columns={columns}
            rowSelection={false}
            pageSizeOptions={[5, 10, 25, 50]}
          />
        </div>
      </div>
    </div>
  );
};

export default Customers;
