import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import logo from "../assets/images/navigation/logo_transparent.png";

export const PDFDocument = (props: any) => {
  const order = props?.order;
  const customer = order?.customerInfo;
  const name = customer?.name ?? "";
  const address = customer?.invoiceAddress ?? customer?.address ?? {};
  const contactPerson = customer?.invoicePerson ?? customer.contactPerson ?? "";
  const accountNumber = customer.accountNumber ?? "";
  const phoneNumber = customer.phoneNumber ?? "";
  const items = order?.items ?? [];
  const invoiceId = order?.invoiceId;
  const orderId = order?.orderId;
  const created = order.created
    .toDate()
    .toISOString()
    .split("T")[0]
    .replaceAll("-", ".");
  const deliveryDate = order?.deliveryDate
    ? order?.deliveryDate
        .toDate()
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "/")
    : order.created.toDate().toISOString().split("T")[0].replaceAll("-", "/");
  const delivery = 10;
  const discount = order.amount?.breakdown?.discount?.value ?? 0;
  const styles = StyleSheet.create({
    page: {
      color: "black",
      fontSize: 10,
      margin: 24,
      padding: 12,
    },
    section: {},
    viewerLeft: {
      marginTop: 55,
      width: (window.innerWidth * 2) / 3, //the pdf viewer will take up all of the width and height
    },
    viewerRight: {
      width: (window.innerWidth * 1) / 3, //the pdf viewer will take up all of the width and height
      fontSize: 9,
      textAlign: "left",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            marginHorizontal: 10,
            paddingHorizontal: 10,
          }}
        >
          <View style={styles.viewerLeft}>
            <Text style={{ textDecoration: "underline", fontSize: 8 }}>
              Soju Halle GmbH, An den Pappeln 4a, 21521 Wohltorf
            </Text>
            <Text style={{ marginVertical: 12 }}>{contactPerson}</Text>
            <Text>{name}</Text>
            <Text>{address?.address_line_1 ?? "Add 1"}</Text>
            <Text>
              {address?.postal_code ?? "postal code"},{" "}
              {address?.admin_area_1 ?? "city"}
            </Text>
            <Text>Tel: {phoneNumber}</Text>
            <Text
              style={{
                marginBottom: 24,
                marginTop: 32,
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Rechnung
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={{ width: "30%" }}>Kundennummer:</Text>
              <Text>{accountNumber}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={{ width: "30%" }}>Bestellungsnummer:</Text>
              <Text>SH-1219-W{invoiceId}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text style={{ width: "30%" }}>Rechnungsnummer:</Text>
              <Text>SH-1219-W{invoiceId}-C</Text>
            </View>
          </View>
          <View style={styles.viewerRight}>
            <Image src={logo} style={{ width: "40px" }} />
            <Text> </Text>
            <Text>Soju Halle GmbH</Text>
            <Text>An den Pappeln 4a</Text>
            <Text>21521 Wohltorf</Text>
            <Text> </Text>
            <Text>+49 (0)410 4962 1236</Text>
            <Text>info@sojuhalle.com</Text>
            <Text> </Text>
            <Text>www.sojuhalle.com</Text>
            <Text> </Text>
            <Text>Geschäftsführer Young-Sam Hur</Text>
            <Text>Amtsgericht Lübeck</Text>
            <Text>HRB 24430 HL</Text>
            <Text>USt-IdNr. DE365228391</Text>
            <Text> </Text>
            <Text>Bankverbindungen</Text>
            <Text>Deutsche Bank</Text>
            <Text>BLZ 2007 0000</Text>
            <Text>IBAN DE30 2007 0000 0583 9287 00</Text>
            <Text>SWIFT/BIC code DEUTDEHHXXX</Text>
          </View>
        </View>
        <View
          style={{
            marginHorizontal: 10,
            paddingHorizontal: 10,
            width: "90%",
          }}
        >
          <View
            style={{
              marginVertical: 24,
              fontSize: 11,
              fontWeight: "bold",
              fontStyle: "bold",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Text style={{ width: "80%" }}>
              Subject: {items.map((item: any) => item.name).join(", ")}
            </Text>
            <Text style={{ width: "20%", fontSize: 9, textAlign: "right" }}>
              Wohltorf, ({created})
            </Text>
          </View>
          <Text> </Text>
          <Text>
            Thank you for your order. We charge the following amount for
            Bestellungsnummer: SH-1219-W{invoiceId}. Please pay the payment
            within 14 days from the date that this invoice is issued.
          </Text>
          <Text> </Text>
          <Text> </Text>
          <View
            style={{
              border: 2,
              flexDirection: "row",
              //marginHorizontal: 4,
              display: "flex",
              height: "50%",
            }}
          >
            <View style={{ borderRight: 1, width: "20%", textAlign: "center" }}>
              <View style={{ height: "60%", borderBottom: 1 }}>
                <Text>Order Nr.</Text>
                <Text>(Delivery Date)</Text>
                <Text> </Text>
                <Text>
                  {invoiceId}-{orderId}
                </Text>
                <Text>({deliveryDate})</Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text> </Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text>Delivery</Text>
              </View>
              {discount > 0 && (
                <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                  <Text>Discount</Text>
                </View>
              )}
              <View style={{ height: "15%", paddingTop: 5 }}>
                <Text> </Text>
              </View>
            </View>
            <View style={{ borderRight: 1, width: "40%", textAlign: "center" }} >
              <View style={{ height: "60%", borderBottom: 1 }}>
                <Text>Description</Text>
                <Text> </Text>
                <Text> </Text>
                {/* USE MAP HERE */}
                {items.map((item: any, i: number) => (
                  <Text
                    style={{ textAlign: "left", marginHorizontal: 8 }}
                    key={item.id+String(i)}
                  >
                    {item.name}, Alc.
                  </Text>
                ))}
              </View>
              <View
                style={{
                  borderBottom: 1,
                  height: "10%",
                  paddingTop: 5,
                }}
              >
                <Text>Net Amount</Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text>Delivery</Text>
              </View>
              {discount > 0 && (
                <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                  <Text>Discount</Text>
                </View>
              )}
              <View style={{ height: "15%", paddingTop: 5 }}>
                <Text> </Text>
                <Text> </Text>
                <Text>Gross Amount (incl. MwSt 19%)</Text>
              </View>
            </View>
            <View style={{ borderRight: 1, width: "20%", textAlign: "center" }}>
              <View style={{ height: "60%", borderBottom: 1 }}>
                <Text>Quantity</Text>
                <Text>(Bottle)</Text>
                <Text> </Text>
                {items.map((item: any) => (
                  <Text key={item.id}>{item.quantity}</Text>
                ))}
              </View>
              <View
                style={{
                  borderBottom: 1,
                  height: "10%",
                  paddingTop: 5,
                }}
              >
                <Text>
                  {items.reduce(
                    (accu: any, curr: any) =>
                      Number(accu) + Number(curr.quantity),
                    0,
                  )}
                </Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text></Text>
              </View>
              {discount > 0 && (
                <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                  <Text></Text>
                </View>
              )}
              <View style={{ height: "15%", paddingTop: 5 }}>
                <Text> </Text>
              </View>
            </View>
            <View style={{ borderRight: 1, width: "20%", textAlign: "center" }}>
              <View style={{ height: "60%", borderBottom: 1 }}>
                <Text>Unit Price</Text>
                <Text>(Eur/bottle)</Text>
                <Text> </Text>
                {items.map((item: any) => (
                  <Text
                    key={item.id}
                    style={{ textAlign: "right", marginRight: 8 }}
                  >
                    {Number(item.unit_amount.value).toFixed(2)} EUR
                  </Text>
                ))}
              </View>
              <View
                style={{
                  borderBottom: 1,
                  height: "10%",
                  paddingTop: 5,
                }}
              >
                <Text style={{ textAlign: "right", marginRight: 8 }}></Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {Number(delivery).toFixed(2)} EUR
                </Text>
              </View>
              {discount > 0 && (
                <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                  <Text style={{ textAlign: "right", marginRight: 8 }}>
                    - {Number(discount).toFixed(2)} EUR
                  </Text>
                </View>
              )}
              <View style={{ height: "15%", paddingTop: 5 }}>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  Netto
                </Text>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  MwSt. 19%
                </Text>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  Brutto
                </Text>
              </View>
            </View>
            <View style={{ width: "20%", textAlign: "center" }}>
              <View style={{ height: "60%", borderBottom: 1 }}>
                <Text>Total Price</Text>
                <Text> </Text>
                <Text> </Text>
                {items.map((item: any) => {
                  const itemNet = (
                    Number(item.unit_amount.value) * item.quantity
                  ).toFixed(2);
                  return <Text key={item.id}>{itemNet} EUR</Text>;
                })}
              </View>
              <View
                style={{
                  borderBottom: 1,
                  height: "10%",
                  paddingTop: 5,
                }}
              >
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {(
                    Math.round(
                      items
                        .map(
                          (item: any) =>
                            Number(item.unit_amount.value) * item.quantity,
                        )
                        .reduce(
                          (accu: number, curr: number) => curr + accu,
                          0,
                        ) * 100,
                    ) / 100
                  ).toFixed(2)}{" "}
                  EUR
                </Text>
              </View>
              <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {Number(delivery).toFixed(2)} EUR
                </Text>
              </View>
              {discount > 0 && (
                <View style={{ borderBottom: 1, height: "10%", paddingTop: 5 }}>
                  <Text style={{ textAlign: "right", marginRight: 8 }}>
                    - {Number(discount).toFixed(2)} EUR
                  </Text>
                </View>
              )}
              <View style={{ height: "15%", paddingTop: 5 }}>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {(
                    items
                      .map(
                        (item: any) =>
                          Number(item.unit_amount.value) * item.quantity,
                      )
                      .reduce((accu: number, curr: number) => curr + accu, 0) +
                    delivery -
                    discount
                  ).toFixed(2)}{" "}
                  EUR
                </Text>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {(
                    (items
                      .map(
                        (item: any) =>
                          Number(item.unit_amount.value) * item.quantity,
                      )
                      .reduce((accu: number, curr: number) => curr + accu, 0) +
                      delivery -
                      discount) *
                    0.19
                  ).toFixed(2)}{" "}
                  EUR
                </Text>
                <Text style={{ textAlign: "right", marginRight: 8 }}>
                  {(
                    (items
                      .map(
                        (item: any) =>
                          Number(item.unit_amount.value) * item.quantity,
                      )
                      .reduce((accu: number, curr: number) => curr + accu, 0) +
                      delivery -
                      discount) *
                    1.19
                  ).toFixed(2)}{" "}
                  EUR
                </Text>
              </View>
            </View>
          </View>
          <Text> </Text>
          <Text>Bank Information</Text>
          <Text> </Text>
          <Text>Soju Halle GmbH</Text>
          <Text>Deutsche Bank</Text>
          <Text>BLZ 2007 0000</Text>
          <Text>IBAN DE30 2007 0000 0583 9287 00</Text>
          <Text>SWIFT/BIC code DEUTDEHHXXX</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
